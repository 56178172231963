<template>
  <div class="project42-floating-button" @click="openModal">
    <span class="project42-floating-button__text">{{ text }}</span>
    <div class="project42-floating-button__circle">
      <span class="project42-floating-button__circle__icon icon-star"></span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FloatingButton",
  props: {
    text: {
      type: String,
      required: false,
    },
  },
  methods: {
    ...mapActions("modal", ["setOpen", "setType"]),
    openModal() {
      this.setOpen(true);
      this.setType("feedback");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.project42-floating-button {
  // position and layout
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;

  // display and visibility
  display: flex;

  // box model
  width: fit-content;
  height: fit-content;
  padding: 1rem;
  border-radius: 50px;

  // background
  background-color: #ffffff;
  box-shadow: 0px 6px 18px 0px #98a6ae99;
  cursor: pointer;

  &__circle {
    // position and layout
    align-items: center;
    justify-content: center;

    // display and visibility
    display: flex;

    // box model
    width: 60px;
    height: 60px;
    border-radius: 100%;

    // background
    background-color: #f17023;

    &__icon {
      // typography
      font-size: 1.5rem;
      color: white;
    }
  }
}

@include mobile {
  .project42-floating-button {
    // box model
    padding: 0.5rem;

    &__text {
      // display and visibility
      display: none;
    }
  }
}
</style>
