<template>
  <div class="overlay-box">
    <div>
      <div class="overlay-box__title">{{ video.title }}</div>
      <div class="overlay-box__author">
        <user-avatar :username="video.owner?.username" :size="50" />
        <div class="overlay-box__author__text">
          by {{ video.owner?.name }} {{ video.owner?.surname }}
        </div>
      </div>
      <div class="overlay-box__description">
        {{ video.description }}
      </div>
      <div class="overlay-box__casting" v-if="video.casting.length > 0">
        <span class="has-text-weight-bold">Casting</span>
        <span class="overlay-box__casting__text">{{
          video.casting?.join(", ")
        }}</span>
      </div>
      <div class="overlay-box__actions">
        <button-icon
          v-if="!editable"
          text="More info"
          iconClass="icon-info"
          iconSize="24px"
          cssBackgroundColor="#ffffff"
          cssBorderRadius="12px"
          cssPadding="8px 18px"
          @click="
            () =>
              $router.push({ name: 'video', params: { video_id: video.id } })
          "
        />
        <button-icon
          v-if="editable"
          text="Remove from promotional"
          iconClass="icon-trash"
          cssBackgroundColor="#FF5252"
          cssBorderRadius="12px"
          cssPadding="14px 22px"
          @click="removePromotional()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import UserAvatar from "@/components/shared/UserAvatar.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import DOMPurify from "dompurify";

export default {
  name: "OverlayBox",
  components: {
    UserAvatar,
    ButtonIcon,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("videos", ["removeVideofromPromotional"]),
    removePromotional() {
      const sanitizedTitle = DOMPurify.sanitize(this.video.title);
      this.$buefy.dialog.confirm({
        title: "Remove Video",
        message: `Are you sure you want to <b>remove</b> the video <b>"${sanitizedTitle}"</b> from the promotional videos?`,
        confirmText: "Remove video",
        type: "is-danger",
        onConfirm: () => this.removeVideofromPromotional(this.video.id),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.overlay-box {
  // position and layout
  position: absolute;
  top: calc(20% + 52.5px);
  flex-direction: column;
  justify-content: center;

  // display and visibility
  display: flex;

  // box model
  border-bottom: 0.25rem solid #f17023;
  border-radius: 0 1rem 1rem 0;
  max-width: 37.5rem;
  height: 50%;
  padding-left: 4rem;
  padding-right: 4rem;

  // background
  background-color: black;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(16px);

  &__title {
    // clipping
    overflow: hidden;

    // typography
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
  }

  &__author {
    // position and layout
    flex-direction: row;
    align-items: center;
    column-gap: 1.25rem;

    // display and visibility
    display: flex;

    // typography
    color: white;

    &__text {
      // clipping
      overflow: hidden;

      // typography
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__description {
    // display and visibility
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    // clipping
    overflow: hidden;

    // box model
    margin-top: 0.5rem;

    // typography
    font-size: 1rem;
    color: white;
  }

  &__casting {
    // position and layout
    flex-direction: column;

    // display and visibility
    display: flex;

    // box model
    margin-top: 0.75rem;

    // typography
    color: white;

    &__text {
      // clipping
      overflow: hidden;

      // typography
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__actions {
    // position and layout
    flex-direction: row;
    column-gap: 26px;

    // display and visibility
    display: flex;

    // box model
    margin-top: 0.75rem;
  }
}

@include mobile {
  .overlay-box {
    // box model
    padding-left: 2rem;
    padding-right: 2rem;
    width: 90%;

    &__title {
      // typography
      font-size: 2rem;
    }

    &__author {
      // typography
      font-size: 1rem;
    }
  }
}

@include tablet {
  .overlay-box {
    // box model
    width: 60%;

    &__title {
      // typography
      font-size: 2.5rem;
    }

    &__author {
      // typography
      font-size: 1rem;
    }
  }
}

@include desktop {
  .overlay-box {
    // box model
    width: 50%;

    &__title {
      // typography
      font-size: 3rem;
    }

    &__author {
      // typography
      font-size: 1.5rem;
    }
  }
}

@media (max-height: 600px) {
  .overlay-box {
    &__author {
      // display and visibility
      display: none;
    }

    &__description {
      // display and visibility
      display: none;
    }

    &__casting {
      // display and visibility
      display: none;
    }
  }
}
</style>
