import Vue from "vue";
import axios from "axios";

const state = () => ({
  categories: null,
});
const getters = {
  categories: (state) => JSON.parse(JSON.stringify(state.categories)),
};
const actions = {
  fetchCategories({ commit }) {
    axios.get(`${Vue.prototype.$config.apiUrl}/category`).then((response) => {
      commit("setCategories", response.data);
    });
  },
};
const mutations = {
  setCategories(state, categories) {
    state.categories = JSON.parse(JSON.stringify(categories));
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
