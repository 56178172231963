<template>
  <div class="author-hint">
    <user-avatar :username="user.username" :size="50" />
    <div class="author-hint__name">{{ user.name }} {{ user.surname }}</div>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";

export default {
  name: "AuthorHint",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    UserAvatar,
  },
};
</script>

<style lang="scss" scoped>
.author-hint {
  // position and layout
  flex-direction: column;
  align-items: center;
  gap: 6px;

  // display and visibility
  display: flex;

  &__name {
    // box model
    max-width: 120px;

    // clipping
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    // typography
    font-size: 0.85rem;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    color: #000000;
  }
}
</style>
