<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ modal_title }}</p>
      <span
        class="modal-card-head-icon icon-x"
        @click="
          () => {
            setOpen(false);
            setItem(null);
          }
        "
      ></span>
    </header>
    <section class="modal-card-body">
      <b-field label="Title">
        <b-input type="text" v-model="title" placeholder="Title" required>
        </b-input>
      </b-field>
      <b-field label="Categories">
        <b-taginput
          v-model="selectedCategories"
          :data="filteredCategories"
          open-on-focus
          autocomplete
          field="name"
          placeholder="Add a Category"
          type="is-categories"
          rounded
          @typing="getFilteredCategories"
          @remove="getFilteredCategories"
          @focus="getFilteredCategories"
        >
        </b-taginput>
      </b-field>

      <b-field label="Description">
        <b-input
          maxlength="500"
          type="textarea"
          v-model="description"
          placeholder="Description"
          required
        >
        </b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <div style="margin-left: 0">
        <b-field v-if="item && videoIsPresent">
          <b-checkbox v-model="draft">Draft</b-checkbox>
        </b-field>
        <b-field v-if="!item || !videoIsPresent">
          <b-checkbox disabled :value="true">Draft</b-checkbox>
        </b-field>
      </div>
      <div style="margin-left: auto; margin-right: 0">
        <button-icon
          v-if="item"
          @click="confirmDeleteSerie()"
          text="Delete serie"
          iconClass="icon-x"
          iconSide="right"
          cssBackgroundColor="#FFFFFF"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="black"
        />
        <button-icon
          :disabled="!(this.title && this.description)"
          v-if="item"
          @click="update(serie)"
          text="Update serie"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
        />
        <button-icon
          :disabled="!(this.title && this.description)"
          v-if="!item"
          @click="create(serie)"
          text="Create serie"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import { mapActions, mapGetters } from "vuex";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import DOMPurify from "dompurify";

export default {
  name: "SeriesModal",
  components: {
    ButtonIcon,
  },
  data() {
    return {
      id: null,
      title: "",
      description: "",
      selectedCategories: [],
      creation_date: "",
      draft: true,
      filteredCategories: [],
    };
  },
  async mounted() {
    await this.fetchCategories();
    if (this.item) {
      await this.fetchSeriesById(this.item.id);
      this.id = this.item.id;
      this.title = this.item.title;
      this.description = this.item.description;
      this.selectedCategories = this.item.categories;
      this.creation_date = this.item.creation_date;
      this.draft = this.item.draft;
    }
  },
  computed: {
    ...mapGetters("categories", { video_categories: "categories" }),
    ...mapGetters("modal", ["item"]),
    ...mapGetters("admin", ["impersonated_user"]),
    ...mapGetters("series", ["series"]),
    serie() {
      return {
        id: this.id || null,
        videos: [],
        title: this.title,
        description: this.description,
        categories: this.selectedCategories.map((category) => category.id),
        creation_date: this.creation_date || null,
        owner_username: utils.isAdmin()
          ? this.impersonated_user
          : this.$keycloak.tokenParsed.preferred_username,
      };
    },
    modal_title() {
      return this.item ? `Update Serie "${this.title}"` : "Create New Serie";
    },
    videoIsPresent() {
      return this.series?.seasonsWithVideos?.some(
        (season) => season.episodesWithVideos.length > 0
      );
    },
  },
  methods: {
    ...mapActions("categories", ["fetchCategories"]),
    ...mapActions("series", [
      "createSerie",
      "updateSerie",
      "deleteSerie",
      "fetchSeriesById",
    ]),
    ...mapActions("modal", ["setOpen", "setItem"]),
    confirmDeleteSerie() {
      const sanitizedTitle = DOMPurify.sanitize(this.title);
      this.$buefy.dialog.confirm({
        title: "Deleting Serie",
        message: `Are you sure you want to <b>delete</b> the serie "${sanitizedTitle}"? </br>This action cannot be undone.`,
        confirmText: "Delete Serie",
        type: "is-danger",
        onConfirm: () =>
          this.deleteSerie(this.serie.id).then(() => this.$emit("closeModal")),
      });
    },
    update() {
      if (!(this.title && this.description)) return;
      this.updateSerie({ ...this.serie, draft: this.draft }).then(() =>
        this.$emit("closeModal")
      );
    },
    create() {
      if (!(this.title && this.description)) return;
      this.createSerie(this.serie).then((newSerie) => {
        this.$emit("switchModal", "SeriesCropper", newSerie);
      });
    },
    getFilteredCategories() {
      this.filteredCategories = this.video_categories?.filter((el) => {
        return !this.selectedCategories.map((c) => c.id).includes(el.id);
      });
    },
  },
  watch: {
    video_categories() {
      this.filteredCategories = this.video_categories;
    },
  },
};
</script>
