<template>
  <div class="actorpage">
    <div>
      <swiper-list
        v-if="actorVideos && actorVideos.length > 0"
        :title="`Videos featuring ${$route.params.name}`"
        :items="actorVideos"
      />
      <p v-else>No videos found for this actor.</p>
    </div>
  </div>
</template>

<script>
import SwiperList from "@/components/home/SwiperList.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ActorPageView",
  components: {
    SwiperList,
  },
  mounted() {
    this.fetchActorVideos(this.$route.params.name);
  },
  computed: {
    ...mapGetters("videos", ["actorVideos"]),
  },
  methods: {
    ...mapActions("videos", ["fetchActorVideos"]),
  },
  watch: {
    "$route.params.name"() {
      this.fetchActorVideos(this.$route.params.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.actorpage {
  // position and layout
  flex-direction: column;
  row-gap: 4rem;

  // display and visibility
  display: flex;

  // box model
  padding-top: 11rem;
  height: fit-content;
}

@include mobile {
  .actorpage {
    &__user {
      // position and layout
      flex-direction: column;
      row-gap: 3rem;
    }
  }
}
</style>
