<template>
  <div class="impersonate-user">
    <div class="impersonate-user__message">
      <span class="icon-warning impersonate-user__message__icon" />
      <span>You are impersonating</span>
      <span class="has-text-weight-bold">{{ impersonated_user }}</span>
      <span class="icon-warning impersonate-user__message__icon" />
    </div>
    <button-icon
      v-if="isAdmin && !is_admin_modal_open"
      text="Change impersonated user"
      iconClass="icon-person"
      iconSide="right"
      cssBackgroundColor="#ffffff"
      cssBorderRadius="12px"
      cssPadding="8px 12px"
      cssBorder="1px solid #E8EBED"
      cssTextColor="black"
      cssIconColor="black"
      @click="() => setIsAdminModalOpen(true)"
    />
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import utils from "@/utils/utils";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ImpersonateUser",
  components: {
    ButtonIcon,
  },
  computed: {
    ...mapGetters("admin", ["impersonated_user", "is_admin_modal_open"]),
    isAdmin() {
      return utils.isAdmin();
    },
  },
  methods: {
    ...mapActions("admin", ["setIsAdminModalOpen"]),
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.impersonate-user {
  // position and layout
  flex-direction: row;
  align-items: center;
  column-gap: 3rem;

  // display and visibility
  display: flex;

  //box model
  padding: 20px 0 40px calc(4rem - 8px);
  width: fit-content;

  &__message {
    // position and layout
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;

    // display and visibility
    display: flex;

    // typography
    font-size: 2rem;
    color: black;

    &__icon {
      // typography
      color: red;
    }
  }
}

@include touch {
  .impersonate-user {
    // position and layout
    flex-direction: column;
    row-gap: 1rem;

    // display and visibility
    display: flex;

    //box model
    padding: 20px 0 40px 0;
    width: 100%;

    &__message {
      // position and layout
      flex-direction: row;
      row-gap: 0.5rem;

      // display and visibility
      display: flex;

      // typography
      font-size: 1rem;
    }
  }
}
</style>
