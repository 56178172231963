import Vue from "vue";
import axios from "axios";

const state = () => ({
  is_admin_modal_open: true,
  impersonated_user: null,
  impersonable_users: [],
});
const getters = {
  is_admin_modal_open: (state) => state.is_admin_modal_open,
  impersonated_user: (state) => state.impersonated_user,
  impersonable_users: (state) =>
    JSON.parse(JSON.stringify(state.impersonable_users)),
};
const actions = {
  setIsAdminModalOpen({ commit }, isOpen) {
    commit("setIsAdminModalOpen", isOpen);
  },
  setImpersonatedUser({ commit }, impersonated_user) {
    commit("setImpersonatedUser", impersonated_user);
    commit("series/setSelectedSerie", null, { root: true });
    commit("seasons/setSelectedSeason", null, { root: true });
  },
  fetchImpersonableUsers({ commit }) {
    axios.get(`${Vue.prototype.$config.apiUrl}/user/all`).then((response) => {
      commit("setImpersonableUsers", response.data);
    });
  },
};
const mutations = {
  setIsAdminModalOpen(state, isOpen) {
    state.is_admin_modal_open = isOpen;
  },
  setImpersonatedUser(state, impersonated_user) {
    state.impersonated_user = impersonated_user;
  },
  setImpersonableUsers(state, impersonable_users) {
    state.impersonable_users = JSON.parse(JSON.stringify(impersonable_users));
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
