<template>
  <div
    :class="'search-box animate__animated ' + animateClass + ' animate__faster'"
    ref="searchdropdown"
  >
    <span class="search-box__close icon-close" @click="close(false)"></span>
    <search-bar
      @receiveHints="(data) => receiveHints(data)"
      @deleteTag="handleDeleteTag"
      @triggerSearch="close(true)"
      @clearAll="handleClearAll"
      :tags="JSON.parse(JSON.stringify(localTags))"
    />
    <div class="search-box__results">
      <div
        class="search-box__results__container"
        v-if="hints.authors && hints.authors.length > 0"
      >
        <b>Authors</b>
        <div class="search-box__results__container__list">
          <author-hint
            v-for="(author, i) in hints.authors"
            :key="i"
            :user="author"
            @click.native="
              () => addSearchTag(author, 'authors', author.username)
            "
          />
        </div>
      </div>
      <div
        class="search-box__results__container"
        v-if="hints.castings && hints.castings.length > 0"
      >
        <b>Castings</b>
        <div class="search-box__results__container__list">
          <tag-item
            v-for="(casting, i) in hints.castings"
            :key="i"
            :tag="hintToTag(casting, 'castings')"
            :isDeletable="false"
            @click.native="() => addSearchTag(casting, 'castings', casting)"
          />
          <!-- // DAVIDE COMMENT: ok the new style?
          <standard-hint
            v-for="(casting, i) in hints.castings"
            :text="casting"
            :key="i"
            @click.native="() => addSearchTag(casting, 'castings', casting)"
          /> -->
        </div>
      </div>
      <div
        class="search-box__results__container"
        v-if="hints.categories && hints.categories.length > 0"
      >
        <b>Categories</b>
        <div class="search-box__results__container__list">
          <tag-item
            v-for="category in hints.categories"
            :key="category.id"
            :tag="hintToTag(category.name, 'categories')"
            :isDeletable="false"
            @click.native="
              () => addSearchTag(category, 'categories', category.name)
            "
          />
          <!-- // DAVIDE COMMENT: ok the new style?
          <standard-hint
            v-for="(category, i) in hints.categories"
            :text="category.name"
            :key="i"
            @click.native="
              () => addSearchTag(category, 'categories', category.name)
            "
          /> -->
        </div>
      </div>
      <div
        class="search-box__results__container"
        v-if="hints.hashtags && hints.hashtags.length > 0"
      >
        <b>Hashtags</b>
        <div class="search-box__results__container__list">
          <tag-item
            v-for="(hashtag, i) in hints.hashtags"
            :key="i"
            :tag="hintToTag(hashtag, 'hashtags')"
            :isDeletable="false"
            @click.native="() => addSearchTag(hashtag, 'hashtags', hashtag)"
          />
          <!-- // DAVIDE COMMENT: ok the new style?
          <standard-hint
            v-for="(hashtag, i) in hints.hashtags"
            :text="hashtag"
            :key="i"
            @click.native="() => addSearchTag(hashtag, 'hashtags', hashtag)"
          />
          -->
        </div>
      </div>
      <!-- // DAVIDE COMMENT: ok the new logic?
      <div
        class="search-box__results__container"
        v-if="hints.series && hints.series.length > 0"
      >
        <b>Series</b>
        <div class="search-box__results__container__list">
          <image-hint
            v-for="(serie, i) in hints.series"
            :data="serie"
            typology="series"
            :key="i"
            @click.native="() => addSearchTag(serie, 'series', serie.title)"
          />
        </div>
      </div> -->
      <!-- // DAVIDE COMMENT: ok the new logic?
      <div
        class="search-box__results__container"
        v-if="hints.videos && hints.videos.length > 0"
      >
        <b>Videos</b>
        <div class="search-box__results__container__list">
          <image-hint
            v-for="(video, i) in hints.videos"
            :data="video"
            typology="videos"
            :key="i"
            @click.native="() => addSearchTag(video, 'videos', video.title)"
          />
        </div>
      </div> -->
    </div>
    <div class="search-box__results" style="width: 80%">
      <swiper-list
        v-if="previewVideos?.length > 0"
        :items="previewVideos"
        :is-compact="true"
        title="Videos"
        bgColor="transparent"
        @swiperItem_clicked="close"
        :isInSearch="true"
      >
      </swiper-list>
      <swiper-list
        v-if="previewSeries?.length > 0"
        :items="previewSeries"
        :is-compact="true"
        title="Series"
        bgColor="transparent"
        type="series"
        @swiperItem_clicked="close"
        :isInSearch="true"
      >
      </swiper-list>
    </div>
    <!-- DAVIDE COMMENT: to remove after we agree the search is DONE :) -->
    <!-- <div>preview: {{ previewVideos }}</div> -->
    <!-- <div style="margin-top: 20px">results: {{ search_results }}</div> -->
    <!-- <div>{{ localTagsForSearch }}</div> -->
    <!-- {{ hints }} -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SearchBar from "@/components/home/search/SearchBar.vue";
import AuthorHint from "@/components/home/search/hints/AuthorHint.vue";
import SwiperList from "@/components/home/SwiperList.vue";
import TagItem from "@/components/home/search/TagItem.vue";

export default {
  name: "SearchBox",
  components: {
    SearchBar,
    AuthorHint,
    SwiperList,
    TagItem,
  },
  data() {
    return {
      animateClass: "animate__slideInDown",
      hints: {},
      localTags: [],
      localTagsForSearch: {},
    };
  },
  mounted() {
    // add class to body to prevent scrolling
    setTimeout(() => document.body.classList.add("noscroll"), 500);
    this.localTags = JSON.parse(JSON.stringify(this.tags));
    this.localTagsForSearch = JSON.parse(JSON.stringify(this.tags_for_search));
    this.search();
  },
  computed: {
    ...mapGetters("search", ["tags", "tags_for_search", "search_results"]),
    previewVideos() {
      let videosArray = [];
      this.search_results.authors?.forEach((author) =>
        videosArray.push(...author.videos)
      );
      this.search_results.castings?.forEach((author) =>
        videosArray.push(...author.videos)
      );
      this.search_results.hashtags?.forEach((author) =>
        videosArray.push(...author.videos)
      );
      this.search_results.categories?.forEach((author) =>
        videosArray.push(...author.videos)
      );
      if (this.search_results?.videos) {
        videosArray.push(...this.search_results.videos);
      }
      return this.processArray(videosArray, "id");
    },
    previewSeries() {
      let seriesArray = [];
      this.search_results.authors?.forEach((author) =>
        seriesArray.push(...author.series)
      );
      this.search_results.categories?.forEach((author) =>
        seriesArray.push(...author.series)
      );
      if (this.search_results?.series) {
        seriesArray.push(...this.search_results.series);
      }
      return this.processArray(seriesArray, "id");
    },
  },
  methods: {
    ...mapActions("search", [
      "setIsSearchBoxOpen",
      "setTags",
      "setTagsForSearch",
      "triggerSearch",
    ]),
    close(alsoSearch = false) {
      if (alsoSearch) {
        this.search();
      }
      // remove class from body to allow scrolling
      document.body.classList.remove("noscroll");

      this.animateClass = "animate__slideOutUp";
      setTimeout(() => this.setIsSearchBoxOpen(false), 800);

      if (alsoSearch) {
        this.$router.push({ name: "search" }).catch(() => {});
      }
    },
    search() {
      this.setTags(this.localTags);
      this.handleVideoAndSeriesLocalTags();
      this.setTagsForSearch(this.localTagsForSearch);
      this.triggerSearch();
      this.$matomo?.trackEvent("search", "trigger");
    },
    receiveHints(data) {
      this.hints = data;
    },
    addSearchTag(tag, type, shortened) {
      var clone = {};
      if (type !== "castings" && type !== "hashtags") {
        clone = JSON.parse(JSON.stringify(tag));
      }
      clone.type = type;
      clone.tag = shortened;

      if (this.localTags.includes(JSON.stringify(clone))) return;

      this.localTags.push(JSON.stringify(clone));

      if (!this.localTagsForSearch[type]) {
        this.localTagsForSearch[type] = [];
      }
      if (type === "castings" || type === "hashtags") {
        this.localTagsForSearch[type].push(tag);
      } else {
        delete clone.type;
        delete clone.tag;
        this.localTagsForSearch[type].push(clone);
      }
      this.search();
    },
    handleDeleteTag(tag) {
      this.localTags.splice(this.localTags.indexOf(tag), 1);

      var clone = JSON.parse(tag);
      if (clone.type === "castings" || clone.type === "hashtags") {
        this.localTagsForSearch[clone.type].splice(
          this.localTagsForSearch[clone.type].indexOf(clone.tag),
          1
        );
      } else {
        delete clone.type;
        delete clone.tag;
        this.localTagsForSearch[JSON.parse(tag).type].splice(
          this.localTagsForSearch[JSON.parse(tag).type]
            .map((e) => JSON.stringify(e))
            .indexOf(JSON.stringify(clone)),
          1
        );
      }
      this.search();
    },
    handleClearAll() {
      this.localTags = [];
      this.localTagsForSearch = {};
      this.search();
    },
    handleVideoAndSeriesLocalTags() {
      this.localTagsForSearch["videos"] = this.hints["videos"];
      this.localTagsForSearch["series"] = this.hints["series"];
    },
    hintToTag(hintName, tagType) {
      // console.log(hintName);
      // console.log(tagType);
      // console.log(
      //   JSON.stringify({
      //     name: hintName,
      //     type: tagType,
      //     tag: hintName,
      //   })
      // );
      return JSON.stringify({
        name: hintName,
        type: tagType,
        tag: hintName,
      });
    },
    processArray(arr, property) {
      const countMap = new Map();

      arr.forEach((obj) => {
        const idValue = obj[property];
        countMap.set(idValue, (countMap.get(idValue) || 0) + 1);
      });

      const uniqueArr = [...new Set(arr.map((obj) => obj[property]))];
      const result = uniqueArr.map((idValue) => {
        const matchingObj = arr.find((obj) => obj[property] === idValue);
        return {
          ...matchingObj,
          numerosity: countMap.get(idValue),
        };
      });

      result.sort((a, b) => b.numerosity - a.numerosity);

      return result;
    },
    closeDropdown(e) {
      if (!this.$refs.searchdropdown.contains(e.target)) {
        this.close();
      }
    },
  },
  watch: {
    tags() {
      this.localTags = JSON.parse(JSON.stringify(this.tags));
    },
    tags_for_search() {
      this.localTagsForSearch = JSON.parse(
        JSON.stringify(this.tags_for_search)
      );
    },
    hints() {
      this.search();
    },
  },
  updated() {
    setTimeout(() => window.addEventListener("click", this.closeDropdown), 400);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeDropdown);
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.search-box {
  // position and layout
  position: fixed;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  top: 104px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 10px 0 10px;

  // display and visibility
  display: flex;
  row-gap: 16px;

  // clipping
  overflow-y: scroll;

  // box model
  max-height: 100vh;
  padding: 30px 10px 10px 10px;

  // background
  background-color: #ffffffbb;
  backdrop-filter: blur(8px);

  &__close {
    // position and layout
    position: absolute;
    top: 15px;
    right: 15px;

    // typography
    font-size: 0.8rem;
    color: black;

    // background
    cursor: pointer;
  }

  &__results {
    // position and layout
    flex-direction: column;
    row-gap: 10px;

    // display and visibility
    display: flex;

    // box model
    width: 80%;

    &__container {
      // position and layout
      flex-direction: column;

      // display and visibility
      display: flex;
      row-gap: 4px;

      &__list {
        // position and layout
        column-gap: 24px;

        // display and visibility
        display: flex;

        // clipping
        overflow-x: scroll;

        & > * {
          // background
          cursor: pointer;
        }
      }
    }
  }
}

@include desktop {
  .search-box {
    &__results {
      &__container {
        &__list {
          &::-webkit-scrollbar {
            // display and visibility
            display: block !important;

            // box model
            height: 6px;
          }

          &::-webkit-scrollbar-thumb {
            // box model
            border-radius: 8px;

            // background
            background-color: #ededed;
          }
        }
      }
    }
  }
}
</style>
