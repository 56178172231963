<template>
  <div class="project42-checkbox">
    <input
      type="checkbox"
      :checked="value"
      :style="`accent-color: ${accentColor};`"
      @change="toggle"
    />
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    accentColor: {
      type: String,
      default: "primary",
    },
  },
  methods: {
    toggle() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.project42-checkbox {
  // box model
  width: 20px;
  height: 20px;

  & > input {
    // box model
    width: 20px;
    height: 20px;

    // background
    cursor: pointer;
  }
}
</style>
