import Vue from "vue";
import axios from "axios";

const state = () => ({
  media_hosts: null,
});
const getters = {
  media_hosts: (state) => JSON.parse(JSON.stringify(state.media_hosts)),
};
const actions = {
  fetchMediaHosts({ commit }) {
    axios.get(`${Vue.prototype.$config.apiUrl}/media-host`).then((response) => {
      commit("setMediaHosts", response.data);
    });
  },
};
const mutations = {
  setMediaHosts(state, media_hosts) {
    state.media_hosts = JSON.parse(JSON.stringify(media_hosts));
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
