<template>
  <div class="rankedcontainer">
    <div class="rankedtitle">{{ title }}</div>
    <div class="rankedlist" :id="id">
      <div class="rankedlist-arrow rankedlist-arrow-left" v-if="!hideLeft">
        <span
          class="rankedlist-arrow-icon icon-keyboard_arrow_left"
          @click="scrollLeft"
        ></span>
      </div>
      <ranked-item
        v-for="(item, i) in items"
        :video="item"
        :key="i"
        :position="i + 1"
      />
      <div class="rankedlist-arrow rankedlist-arrow-right" v-if="!hideRight">
        <span
          class="rankedlist-arrow-icon icon-keyboard_arrow_right"
          @click="scrollRight"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import RankedItem from "@/components/shared/RankedItem.vue";

export default {
  name: "RankedList",
  components: {
    RankedItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      hideLeft: true,
      hideRight: false,
    };
  },
  beforeMount() {
    this.id = "rankedlist_" + this._uid;
  },
  mounted() {
    const el = document.getElementById(this.id);

    const updateScrollArrows = () => {
      setTimeout(() => {
        if (el.offsetWidth + el.scrollLeft >= el.scrollWidth - 1) {
          this.hideRight = true;
        } else {
          this.hideRight = false;
        }

        if (el.scrollLeft == 0) {
          this.hideLeft = true;
        } else {
          this.hideLeft = false;
        }
      }, 500);
    };

    // check if right arrow should be hidden
    updateScrollArrows();

    addEventListener("resize", updateScrollArrows);

    el.addEventListener("scroll", updateScrollArrows);
  },
  methods: {
    scrollLeft() {
      const el = document.getElementById(this.id);

      el.scrollLeft -= 300;
      setTimeout(() => (this.hideLeft = el.scrollLeft == 0), 500);
    },
    scrollRight() {
      const el = document.getElementById(this.id);

      el.scrollLeft += 300;
      setTimeout(() => (this.hideLeft = el.scrollLeft == 0), 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.rankedcontainer {
  // position and layout
  flex-direction: column;
  row-gap: 40px;

  // display and visibility
  display: flex;

  // box model
  padding: 20px 8px;
}

.rankedtitle {
  // box model
  padding-left: calc(4rem - 8px);

  // typography
  font-size: 2rem;
  font-weight: 700;
  color: black;
}

.rankedlist {
  // position and layout
  column-gap: 40px;

  // display and visibility
  display: flex;

  // clipping
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  white-space: nowrap;

  &-arrow {
    // position and layout
    position: absolute;
    justify-content: center;

    // display and visibility
    display: flex;

    // box model
    width: 50px;
    height: 305.47px;

    &-icon {
      // box model
      margin-top: 92.5px;
      border-radius: 50%;

      // background
      cursor: pointer;

      // typography
      font-size: 2rem;
      color: black;

      &:hover {
        // animation
        font-size: 2.2rem;
      }
    }

    &-left {
      // position and layout
      left: 0;
      z-index: 1;

      // background
      background: linear-gradient(
        90deg,
        #ffffff 21.28%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &-right {
      // position and layout
      right: 0;
      z-index: 1;

      // background
      background: linear-gradient(
        270deg,
        #ffffff 21.28%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}

@include mobile {
  .rankedlist {
    // clipping
    overflow-x: auto;
  }
}
</style>
