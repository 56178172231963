import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import videos from "@/store/modules/videos";
import search from "@/store/modules/search";
import series from "@/store/modules/series";
import author from "@/store/modules/author";
import seasons from "@/store/modules/seasons";
import categories from "@/store/modules/categories";
import modal from "@/store/modules/modal";
import episodes from "@/store/modules/episodes";
import mediaHosts from "@/store/modules/mediaHosts";
import admin from "@/store/modules/admin";
import picks from "@/store/modules/picks";

export default new Vuex.Store({
  modules: {
    videos,
    search,
    series,
    author,
    seasons,
    categories,
    modal,
    episodes,
    mediaHosts,
    admin,
    picks,
  },
});
