import Vue from "vue";
import axios from "axios";

const state = () => ({
  selected_season: null,
});

const getters = {
  selected_season: (state) => JSON.parse(JSON.stringify(state.selected_season)),
};

const actions = {
  setSelectedSeason({ commit }, season) {
    commit("setSelectedSeason", season);
  },
  createSeason({ dispatch }, season) {
    axios
      .post(`${Vue.prototype.$config.apiUrl}/season`, season)
      .then(() =>
        dispatch("series/refreshSelectedSerie", null, { root: true })
      );
  },
  updateSeason({ dispatch }, season) {
    axios
      .put(`${Vue.prototype.$config.apiUrl}/season`, season)
      .then(() =>
        dispatch("series/refreshSelectedSerie", null, { root: true })
      );
  },
  deleteSeason({ dispatch }, seasonId) {
    axios
      .delete(`${Vue.prototype.$config.apiUrl}/season/${seasonId}`)
      .then(() =>
        dispatch("series/refreshSelectedSerie", null, { root: true })
      );
  },
};

const mutations = {
  setSelectedSeason(state, season) {
    state.selected_season = JSON.parse(JSON.stringify(season));
    if (state.selected_season && state.selected_season.episodesWithVideos) {
      state.selected_season.episodesWithVideos.forEach((episode) => {
        episode.image_url += `?refresh=${Math.random()}`;
      });
    }
  },
  forceRefreshVideoImage(state, videoId) {
    if (state.selected_season && state.selected_season.episodesWithVideos) {
      state.selected_season.episodesWithVideos.find(
        (video) => video.id === videoId
      ).image_url += `?refresh=${Math.random()}`;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
