import Vue from "vue";
import axios from "axios";

const state = () => ({});

const getters = {};

const actions = {
  createEpisode({ dispatch }, episode) {
    axios
      .post(`${Vue.prototype.$config.apiUrl}/episode`, episode)
      .then(() =>
        dispatch("series/refreshSelectedSerie", null, { root: true })
      );
  },
  updateEpisode({ dispatch }, episode) {
    axios
      .put(`${Vue.prototype.$config.apiUrl}/episode`, episode)
      .then(() =>
        dispatch("series/refreshSelectedSerie", null, { root: true })
      );
  },
  deleteEpisode({ dispatch }, episodeId) {
    axios
      .delete(`${Vue.prototype.$config.apiUrl}/episode/${episodeId}`)
      .then(() =>
        dispatch("series/refreshSelectedSerie", null, { root: true })
      );
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
