<template>
  <div class="footerbar">
    <div class="footerbar-top">
      <div>
        <img
          src="/assets/images/p42_logo.png"
          alt="Project42"
          style="filter: grayscale(1)"
        />
      </div>
      <div class="footerbar-top-right">
        <div class="footerbar-top-right-text">
          <span>Share the Knowledge</span>
        </div>
        <div class="footerbar-top-right-icons">
          <div class="footerbar-top-right-icons-icon">
            <b-tooltip
              label="facebook.com/sorintlab"
              position="is-left"
              type="is-dark"
            >
              <a :href="$config.facebookUrl" target="_blank"
                ><span class="icon-facebook"></span
              ></a>
            </b-tooltip>
          </div>
          <div class="footerbar-top-right-icons-icon">
            <b-tooltip
              label="youtube.com/sorintlab"
              position="is-left"
              type="is-dark"
            >
              <a :href="$config.youtubeUrl" target="_blank"
                ><span class="icon-youtube"></span
              ></a>
            </b-tooltip>
          </div>
          <div class="footerbar-top-right-icons-icon">
            <b-tooltip
              label="instagram.com/sorintlab"
              position="is-left"
              type="is-dark"
            >
              <a :href="$config.instagramUrl" target="_blank"
                ><span class="icon-instagram"></span
              ></a>
            </b-tooltip>
          </div>
          <div class="footerbar-top-right-icons-icon">
            <b-tooltip
              label="linkedin.com/sorintlab"
              position="is-left"
              type="is-dark"
            >
              <a :href="$config.linkedinUrl" target="_blank"
                ><span class="icon-linkedin"></span
              ></a>
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div class="footerbar-rule"></div>

    <div class="footerbar-middle">
      <div class="footerbar-middle-categories">
        <tag-item
          v-for="(category, i) in categories"
          :key="i"
          :tag="getCategoryTag(category)"
          :isDeletable="false"
          @click.native="() => searchByCategory(category)"
        />
      </div>
      <div class="footerbar-middle-links">
        <p>
          <a :href="$config.clannUrl" target="_blank">Sorint CLANN</a>
        </p>
        <p>
          <a :href="$config.floatingPointUrl" target="_blank">Floating Point</a>
        </p>
        <p>
          <a :href="$config.leaderBoardUrl" target="_blank">Leader Board</a>
        </p>
        <p><a :href="$config.dock12Url" target="_blank">Dock12</a></p>
        <p>
          <a :href="$config.globalAcademiaUrl" target="_blank"
            >Global Academia</a
          >
        </p>
      </div>
    </div>

    <div class="footerbar-rule"></div>

    <div class="footerbar-bottom">
      <p>2023©Project42 is a Sorint.lab product.</p>
      <p>
        Sorint.lab S.p.A. Via Zanica 17 - 24050 - Grassobbio [BG] - Phone
        +39035697511 - Email welisten@sorint.it - C. FISC. 95164770166 - P.IVA
        03419770163
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TagItem from "@/components/home/search/TagItem.vue";

export default {
  name: "FooterBar",
  components: {
    TagItem,
  },
  data() {
    return {
      stars: 0,
      text: "",
    };
  },
  async mounted() {
    await this.fetchCategories();
  },
  computed: {
    ...mapGetters("categories", ["categories"]),
  },
  methods: {
    ...mapActions("categories", ["fetchCategories"]),
    ...mapActions("search", ["setTagsForSearch", "triggerSearch", "setTags"]),
    searchByCategory(category) {
      this.setTags([
        JSON.stringify({ ...category, type: "categories", tag: category.name }),
      ]);
      this.setTagsForSearch({ categories: [category] });
      this.triggerSearch();
      this.$router.push({ name: "search" }).catch(() => {});
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getCategoryTag(category) {
      return JSON.stringify({
        type: "categories",
        tag: category.name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

a {
  text-decoration: none;
  color: #172126;
}

.footerbar {
  // position and layout
  flex-direction: column;

  // display and visibility
  display: flex;

  // box model
  min-height: 514px;
  padding-top: 23px;
  padding-right: calc(4rem - 8px);
  padding-bottom: 92px;
  padding-left: calc(4rem - 8px);

  // background
  background-color: #d9d9d9;

  // typography
  color: #212b31;

  &-top {
    // display and visibility
    display: flex;

    // position and layout
    align-items: baseline;
    flex-direction: row;
    justify-content: space-between;

    // box model
    padding-bottom: 18px;

    &-right {
      // position and layout
      column-gap: 0px;
      flex-direction: row;

      // display and visibility
      display: flex;

      // box model
      height: 32px;

      &-text {
        // box model
        min-width: 244px;

        // typography
        font-size: 1rem;
        line-height: 32px;
        font-weight: 700;
        font-style: normal;
        text-align: left;
        letter-spacing: 1px;
        color: #5a6972;
      }

      &-icons {
        // position and layout
        column-gap: 21px;

        // display and visibility
        display: flex;

        &-icon {
          // typography
          font-size: 1.5rem;
          color: #172126;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &-rule {
    // box model
    border: 1px solid #5a6972;
  }

  &-middle {
    // display and visibility
    display: flex;
    justify-content: space-between;

    // position and layout
    flex-direction: row;

    // box model
    padding-top: 20px;
    padding-bottom: 20px;

    &-categories {
      // position and layout
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 1rem;
      align-content: flex-start;

      // display and visibility
      display: flex;

      // box model
      max-width: 500px;

      // background
      cursor: pointer;
    }

    &-links {
      // position and layout
      flex-direction: column;
      row-gap: 8px;

      // display and visibility
      display: flex;

      // box model
      width: fit-content;

      // typography
      font-size: 1rem;
      font-weight: 700;
      font-style: normal;
      text-align: right;
      letter-spacing: 1px;
      color: #212b31;

      > p {
        // box model
        height: 32px;

        // typography
        line-height: 32px;
      }
    }
  }

  &-bottom {
    // box model
    min-height: 36px;

    // typhography
    font-size: 0.75;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.9999998807907104px;
    text-align: left;
    color: #5a6972;
  }

  @media screen and (max-width: 476px) {
    .footerbar {
      &-top {
        &-right {
          // position and layout
          flex-direction: column;

          // box model
          margin-bottom: 28px;
        }
      }
    }
  }

  @include mobile {
    .footerbar {
      &-top {
        // position and layout
        flex-direction: column;

        &-logo {
          // box model
          margin-top: 28px;
        }

        &-right {
          // box model
          margin-top: 28px;
          margin-bottom: 0px;
          height: fit-content;

          // position and layout
          flex-direction: column;
          row-gap: 18px;
        }
      }

      &-middle {
        // position and layout
        flex-direction: column;
        row-gap: 18px;

        &-links {
          // typhography
          text-align: left;
        }
      }
    }
  }
}
</style>
