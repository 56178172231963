<template>
  <img
    :src="`${$config.savatarUrl}/${username}?s=${savatarSize}`"
    alt="Avatar image"
    :style="cssUserAvatar"
  />
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    username: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  computed: {
    cssUserAvatar() {
      return {
        "box-shadow": `rgba(100, 100, 111, 0.2) ${this.size / 40}px ${
          this.size / 20
        }px ${this.size / 13}px 0`,
        "border-radius": this.randomizeRadius(32, 68),
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    savatarSize() {
      return Math.ceil(this.size / 50) * 50;
    },
  },
  methods: {
    getRandomValue(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    randomizeRadius(min, max) {
      const a = this.getRandomValue(min, max);
      const b = this.getRandomValue(min, max);
      const c = this.getRandomValue(min, max);
      const d = this.getRandomValue(min, max);
      const radius = `${a}% ${100 - a}% ${c}% ${100 - c}% / ${d}% ${b}% ${
        100 - b
      }% ${100 - d}%`;
      return radius;
    },
  },
};
</script>
