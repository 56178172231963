<template>
  <div
    :class="
      disabled
        ? 'project42-button-icon project42-button-icon--disabled'
        : 'project42-button-icon project42-button-icon--hoverable'
    "
    :style="`padding: ${cssPadding}; background-color: ${cssBackgroundColor}; border-radius: ${cssBorderRadius}; border: ${cssBorder}; height: ${cssHeight}; width: ${cssWidth};`"
    @click="emitClick"
  >
    <div class="project42-button-icon__text" :style="`color: ${cssTextColor};`">
      {{ text }}
    </div>
    <span
      :class="`project42-button-icon__icon--${iconSide} ` + iconClass"
      :style="`color: ${cssIconColor}; font-size: ${iconSize};`"
    ></span>
  </div>
</template>

<script>
export default {
  name: "ButtonIcon",
  props: {
    text: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
    iconSide: {
      type: String,
      default: "left",
    },
    iconSize: {
      type: String,
      default: "16px",
    },
    cssPadding: {
      type: String,
      default: "0px",
    },
    cssBackgroundColor: {
      type: String,
      default: "transparent",
    },
    cssBorderRadius: {
      type: String,
      default: "0px",
    },
    cssBorder: {
      type: String,
      default: "none",
    },
    cssTextColor: {
      type: String,
      default: "#172126",
    },
    cssIconColor: {
      type: String,
      default: "#172126",
    },
    cssHeight: {
      type: String,
      default: "fit-content",
    },
    cssWidth: {
      type: String,
      default: "fit-content",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      if (!this.disabled) this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.project42-button-icon {
  // position and layout
  column-gap: 12px;
  align-items: center;

  // display and visibility
  display: flex;

  // box model
  width: fit-content;

  // clipping
  cursor: pointer;

  transition: 0.2s all;

  &--disabled {
    // background
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  &--hoverable:hover {
    filter: contrast(1.1);
  }

  &__text {
    // position and layout
    order: 1;
  }

  &__icon {
    &--left {
      // position and layout
      order: 0;
    }

    &--right {
      // position and layout
      order: 2;
    }
  }
}
</style>
