import Vue from "vue";
import axios from "axios";

const state = () => ({
  isSearchBoxOpen: false,
  tags: [],
  tags_for_search: {},
  search_results: [],
});

const getters = {
  isSearchBoxOpen: (state) => state.isSearchBoxOpen,
  tags: (state) => JSON.parse(JSON.stringify(state.tags)),
  tags_for_search: (state) => JSON.parse(JSON.stringify(state.tags_for_search)),
  search_results: (state) => JSON.parse(JSON.stringify(state.search_results)),
};

const actions = {
  setIsSearchBoxOpen({ commit }, isOpen) {
    commit("setIsSearchBoxOpen", isOpen);
  },
  setTags({ commit }, tags) {
    commit("setTags", JSON.parse(JSON.stringify(tags)));
  },
  setTagsForSearch({ commit }, tags) {
    commit("setTagsForSearch", JSON.parse(JSON.stringify(tags)));
  },
  triggerSearch({ commit, state }) {
    axios
      .post(`${Vue.prototype.$config.apiUrl}/search`, {
        ...state.tags_for_search,
      })
      .then((response) => commit("setSearchResults", response.data));
  },
};

const mutations = {
  setIsSearchBoxOpen(state, isOpen) {
    state.isSearchBoxOpen = isOpen;
  },
  setTags(state, tags) {
    state.tags = JSON.parse(JSON.stringify(tags));
  },
  setTagsForSearch(state, tags) {
    state.tags_for_search = JSON.parse(JSON.stringify(tags));
  },
  setSearchResults(state, results) {
    state.search_results = JSON.parse(JSON.stringify(results));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
