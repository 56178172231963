import Vue from "vue";
import axios from "axios";

const state = () => ({
  hasPicks: false,
  receiver: null,
});
const getters = {
  hasPicks: (state) => state.hasPicks,
  receiver: (state) => state.receiver,
};
const actions = {
  fetchPicks({ commit }) {
    axios
      .get(`/accounts/picks`, { baseURL: Vue.prototype.$config.picksUserUrl })
      .then((response) => {
        commit("setHasPicks", response.data.picks);
      });
  },
  fetchReceiver({ commit }, username) {
    axios
      .get(`/accounts/search?type=user&term=${username}`, {
        baseURL: Vue.prototype.$config.picksUserUrl,
      })
      .then((response) => {
        const receiver =
          response?.data?.data?.find((user) => user.owner === username) || null;
        commit("setReceiver", receiver);
      });
  },
};
const mutations = {
  setHasPicks(state, picks) {
    state.hasPicks = picks > 0;
  },
  setReceiver(state, receiver) {
    state.receiver = receiver;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
