<template>
  <b-tooltip
    :label="picksTooltipLabel"
    :type="picksTooltipType"
    position="is-right"
  >
    <div
      :class="{
        'icon-picks': true,
        'icon-picks_disabled': !receiver?.id,
      }"
      @click="sendTip()"
    ></div>
  </b-tooltip>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavBar",
  props: {
    username: {
      type: String,
      required: true,
    },
  },
  created() {
    this.fetchPicks();
    this.fetchReceiver(this.username);
  },
  computed: {
    ...mapGetters("picks", ["receiver", "hasPicks"]),
    picksTooltipLabel() {
      if (!this.hasPicks) {
        return "You haven't any picks to donate to the author";
      }
      if (!this.receiver) {
        return "The author is not a picks user";
      }
      return `Donate 1 pick to the author`;
    },
    picksTooltipType() {
      if (!this.hasPicks) {
        return "is-gray is-light";
      }
      if (!this.receiver) {
        return "is-gray is-light";
      }
      return "is-picks is-light";
    },
  },
  methods: {
    ...mapActions("picks", ["fetchPicks", "fetchReceiver"]),
    sendTip() {
      if (this.receiver?.id) {
        const object = {
          receiverID: this.receiver.id,
          amount: 1,
          url: window.location.origin + window.location.pathname,
          senderNotes: `Project 42: donation from ${this.$keycloak.tokenParsed.preferred_username}`,
        };
        const param = btoa(JSON.stringify(object));
        window.location.href = `${this.$config.picksGatewayUrl}/${param}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-picks {
  // background
  cursor: pointer;

  // typography
  background-color: #5f2eea;
  color: white;
  font-size: 21px;
  width: 32px;
  padding-top: 1px;
  border-radius: 50%;
  text-align: center;

  &:hover {
    // animation
    transform: scale(1.15);
  }

  &_disabled {
    background-color: #cecece88;
    color: white;
    cursor: disabled;
  }
}
</style>
