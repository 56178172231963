import { render, staticRenderFns } from "./AuthorHint.vue?vue&type=template&id=f1d6f7be&scoped=true"
import script from "./AuthorHint.vue?vue&type=script&lang=js"
export * from "./AuthorHint.vue?vue&type=script&lang=js"
import style0 from "./AuthorHint.vue?vue&type=style&index=0&id=f1d6f7be&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1d6f7be",
  null
  
)

export default component.exports