<template>
  <div v-if="isAdmin" class="promcontainer">
    <div class="promtop">
      <div>
        <h2 class="promtop-title">Promotional video</h2>
        <p class="promtop-subtitle">
          Select a video to add to promotional videos
        </p>
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              v-model="selectedVideoCustomField"
              placeholder="Select a Video"
              :open-on-focus="true"
              :data="filteredNotPromotional"
              field="customField"
              @select="(option) => setSelectedVideoId(option)"
              :clearable="false"
            >
            </b-autocomplete>
          </div>
          <div class="column">
            <b-button
              :disabled="!selectedVideoId"
              label="Add"
              type="is-primary"
              @click="addVideo()"
            />
          </div>
        </div>
      </div>
    </div>
    <promotional-carousel :editable="true" />
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import PromotionalCarousel from "@/components/home/carousel/PromotionalCarousel.vue";
import { ToastProgrammatic as Toast } from "buefy";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "PromotionalVideosView",
  components: {
    PromotionalCarousel,
  },
  data() {
    return {
      selectedVideoCustomField: null,
      selectedVideoId: null,
    };
  },
  async mounted() {
    await this.fetchNotPromotional();
  },
  computed: {
    ...mapGetters("videos", ["notPromotional"]),
    isAdmin() {
      return utils.isAdmin();
    },
    enrichedNotPromotional() {
      return this.notPromotional.map((object) => {
        return {
          ...object,
          customField: `${object.title} by ${object.owner.username}`,
        };
      });
    },
    filteredNotPromotional() {
      if (
        this.selectedVideoCustomField !== null &&
        this.selectedVideoCustomField !== undefined
      ) {
        return this.enrichedNotPromotional?.filter((video) => {
          return (
            video.customField
              .toString()
              .toLowerCase()
              .indexOf(this.selectedVideoCustomField.toLowerCase()) >= 0
          );
        });
      } else {
        return this.enrichedNotPromotional;
      }
    },
  },
  methods: {
    ...mapActions("videos", ["fetchNotPromotional", "addVideoToPromotional"]),
    addVideo() {
      this.selectedVideoCustomField = null;
      this.addVideoToPromotional(this.selectedVideoId).then(() => {
        Toast.open({
          duration: 3000,
          message: "Video correctly added to Promotional videos!",
          position: "is-top-right",
          type: "is-success",
        });
        this.selectedVideoId = null;
      });
    },
    setSelectedVideoId(video) {
      this.selectedVideoCustomField = video?.customField;
      this.selectedVideoId = video?.id;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.promcontainer {
  // box model
  padding-top: 100px;
}

.promtop {
  // position and layout
  flex-direction: column;
  row-gap: 10px;
  margin: 30px 0;

  // display and visibility
  display: flex;

  // box model
  padding-left: calc(4rem - 8px);

  // typography
  color: black;

  &-title {
    // typography
    font-size: 2rem;
    font-weight: 700;
  }

  &-subtitle {
    // typography
    font-size: 1.1rem;
    font-weight: 400;

    // position and layout
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }

  &-icon {
    cursor: pointer;
    margin-left: 2rem;
    font-size: 2rem;
    color: #5a6972;
    transition: all 0.3s;

    &:hover {
      color: #394247;
    }
  }
}
</style>
