<template>
  <div class="fallback">
    <div class="content">
      <div class="content-title">PROJECT42</div>
      <div class="content-subtitle">Oops... something wen wrong!</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FallbackView",
};
</script>

<style lang="scss" scoped>
.fallback {
  // position and layout
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // display and visibility
  display: flex;

  // box model
  width: 100vw;
  height: 100vh;

  // background
  background-color: #ffaaaa;
}

.content {
  // position and layout
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  // display and visibility
  display: flex;

  &-title {
    // typography
    font-size: 50px;
    font-weight: 700;
  }

  &-subtitle {
    // typography
    font-size: 20px;
    font-weight: 300;
  }
}
</style>
