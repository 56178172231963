<template>
  <b-modal
    v-model="open"
    :width="640"
    scroll="keep"
    :on-cancel="confirmCloseModal"
  >
    <video-modal
      v-if="type === 'video'"
      @closeModal="closeModal"
      @switchModal="switchModal"
    ></video-modal>
    <series-modal
      v-if="type === 'series'"
      @closeModal="closeModal"
      @switchModal="switchModal"
    ></series-modal>
    <season-modal
      v-if="type === 'season'"
      @closeModal="closeModal"
    ></season-modal>
    <episode-modal
      v-if="type === 'episode'"
      @closeModal="closeModal"
    ></episode-modal>
    <cropper-modal
      v-if="type === 'VideoCropper' || type === 'SeriesCropper'"
      @closeModal="closeModal"
    ></cropper-modal>
    <feedback-modal
      v-if="type === 'feedback'"
      @closeModal="closeModal"
    ></feedback-modal>
  </b-modal>
</template>

<script>
import SeriesModal from "@/components/modal/SeriesModal.vue";
import SeasonModal from "@/components/modal/SeasonModal.vue";
import VideoModal from "@/components/modal/VideoModal.vue";
import EpisodeModal from "@/components/modal/EpisodeModal.vue";
import CropperModal from "@/components/modal/CropperModal.vue";
import FeedbackModal from "@/components/modal/FeedbackModal.vue";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ModalComponent",
  components: {
    SeriesModal,
    SeasonModal,
    VideoModal,
    EpisodeModal,
    CropperModal,
    FeedbackModal,
  },
  computed: {
    ...mapGetters("modal", ["type"]),
    ...mapState("modal", ["open"]),
  },
  methods: {
    ...mapActions("modal", [
      "setOpen",
      "setItem",
      "setType",
      "setEpisodeNumber",
    ]),
    confirmCloseModal() {
      this.$buefy.dialog.confirm({
        title: "Exit modal",
        message: `Some changes may not be saved. Are you sure to proceed?`,
        confirmText: "Proceed",
        type: "is-danger",
        onConfirm: () => this.closeModal(),
      });
    },
    closeModal() {
      this.setOpen(false);
      this.setItem(null);
      this.setEpisodeNumber(null);
    },
    switchModal(modalType, newItem) {
      this.setType(modalType);
      this.setItem(newItem);
    },
  },
};
</script>

<style lang="scss"></style>
