import Vue from "vue";
import axios from "axios";
import utils from "@/utils/utils";

const state = () => ({
  latestSeries: [],
  userSeries: [],
  series: {},
  serieByVideo: null,
  my_series: [],
  selected_serie: null,
});

const getters = {
  latestSeries: (state) => JSON.parse(JSON.stringify(state.latestSeries)),
  userSeries: (state) => JSON.parse(JSON.stringify(state.userSeries)),
  series: (state) => JSON.parse(JSON.stringify(state.series)),
  serieByVideo: (state) => JSON.parse(JSON.stringify(state.serieByVideo)),
  my_series: (state) => JSON.parse(JSON.stringify(state.my_series)),
  selected_serie: (state) => JSON.parse(JSON.stringify(state.selected_serie)),
};

const actions = {
  fetchLatestSeries({ commit }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/serie/latest`)
      .then((response) => {
        commit("setLatestSeries", response.data);
      });
  },
  fetchUserSeries({ commit }, username) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/serie/user/${username}`)
      .then((response) => {
        commit(
          "setUserSeries",
          response.data?.filter((serie) => !serie?.draft)
        );
      });
  },
  fetchSeriesById({ commit }, seriesId) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/serie/${seriesId}`)
      .then((response) => {
        commit("setSeries", response.data);
      });
  },
  fetchSerieByVideo({ commit }, videoId) {
    commit("setSerieByVideo", null);
    axios
      .get(`${Vue.prototype.$config.apiUrl}/serie/video/${videoId}`)
      .then((response) => {
        commit("setSerieByVideo", response.data);
      });
  },
  async fetchMySeries({ commit }, username) {
    await axios
      .get(`${Vue.prototype.$config.apiUrl}/serie/user/${username}`)
      .then((response) => {
        commit("setMySeries", response.data);
      });
  },
  setSelectedSerie({ commit }, serie) {
    if (serie) {
      axios
        .get(`${Vue.prototype.$config.apiUrl}/serie/${serie.id}`)
        .then((response) => {
          commit("setSelectedSerie", response.data);
        })
        .catch(() => {
          commit("setSelectedSerie", null);
        });
    } else {
      commit("setSelectedSerie", serie);
    }
    commit("seasons/setSelectedSeason", null, { root: true });
  },
  refreshSelectedSerie({ getters, commit, dispatch, rootGetters }) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/serie/${getters.selected_serie.id}`)
      .then((response) => {
        commit("setSelectedSerie", response.data);
        // update selected season
        const selected_season = response.data?.seasonsWithVideos?.find(
          (season) => season.id === rootGetters["seasons/selected_season"]?.id
        );
        dispatch(
          "seasons/setSelectedSeason",
          selected_season === undefined
            ? null
            : JSON.parse(JSON.stringify(selected_season)),
          { root: true }
        );
      });
  },
  async createSerie({ getters, dispatch, rootGetters }, serie) {
    let serieId = null;
    await axios
      .post(`${Vue.prototype.$config.apiUrl}/serie`, serie)
      .then(async (response) => {
        serieId = response.data;
        await dispatch(
          "fetchMySeries",
          utils.isAdmin()
            ? rootGetters["admin/impersonated_user"]
            : Vue.prototype.$keycloak.tokenParsed.preferred_username
        );
      });
    return getters.my_series.find((serie) => serie.id === serieId);
  },
  updateSerie({ dispatch, rootGetters }, serie) {
    axios
      .put(`${Vue.prototype.$config.apiUrl}/serie`, serie)
      .then(() =>
        dispatch(
          "fetchMySeries",
          utils.isAdmin()
            ? rootGetters["admin/impersonated_user"]
            : Vue.prototype.$keycloak.tokenParsed.preferred_username
        )
      );
  },
  deleteSerie({ commit, dispatch, rootGetters }, serieId) {
    axios
      .delete(`${Vue.prototype.$config.apiUrl}/serie/${serieId}`)
      .then(() =>
        dispatch(
          "fetchMySeries",
          utils.isAdmin()
            ? rootGetters["admin/impersonated_user"]
            : Vue.prototype.$keycloak.tokenParsed.preferred_username
        )
      )
      .then(() => commit("setSelectedSerie", null));
  },
  updateSerieImage({ commit }, serieImageData) {
    axios
      .put(`${Vue.prototype.$config.apiUrl}/serie/image`, serieImageData)
      .then(() => commit("forceRefreshImage", serieImageData.id));
  },
};

const mutations = {
  setLatestSeries(state, latestSeries) {
    state.latestSeries = JSON.parse(JSON.stringify(latestSeries));
  },
  setUserSeries(state, userSeries) {
    state.userSeries = JSON.parse(JSON.stringify(userSeries));
  },
  setSeries(state, series) {
    state.series = JSON.parse(JSON.stringify(series));
  },
  setSerieByVideo(state, serieByVideo) {
    state.serieByVideo = JSON.parse(JSON.stringify(serieByVideo));
  },
  setMySeries(state, my_series) {
    state.my_series = JSON.parse(JSON.stringify(my_series));
  },
  setSelectedSerie(state, serie) {
    state.selected_serie = JSON.parse(JSON.stringify(serie));
  },
  forceRefreshImage(state, serieId) {
    state.my_series.find(
      (my_serie) => my_serie.id === serieId
    ).image_url += `?refresh=${Math.random()}`;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
