<template>
  <b-modal :active="is_admin_modal_open" has-modal-card :can-cancel="[]">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Choose the user you want to impersonate:</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Impersonate user"
          v-if="impersonable_users && impersonable_users.length > 0"
        >
          <b-select
            placeholder="Select a user"
            required
            v-model="impersonatedUser"
          >
            <option
              v-for="(user, i) in impersonable_users"
              :key="i"
              :value="user.username"
            >
              {{ user.username }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <div style="margin-left: auto; margin-right: 0">
          <b-button
            :disabled="!this.impersonatedUser"
            label="Ok"
            type="is-primary"
            @click="closeModal"
          />
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AdminModal",
  data() {
    return {
      impersonatedUser: this.impersonated_user,
    };
  },
  mounted() {
    if (!this.impersonated_user) {
      this.setImpersonatedUser(this.$keycloak.tokenParsed.preferred_username);
    }
    this.fetchImpersonableUsers();
  },
  computed: {
    ...mapGetters("admin", [
      "impersonated_user",
      "impersonable_users",
      "is_admin_modal_open",
    ]),
  },
  methods: {
    ...mapActions("admin", [
      "fetchImpersonableUsers",
      "setIsAdminModalOpen",
      "setImpersonatedUser",
    ]),
    closeModal() {
      this.setImpersonatedUser(this.impersonatedUser);
      this.setIsAdminModalOpen(false);
    },
  },
  watch: {
    impersonated_user() {
      this.impersonatedUser = this.impersonated_user;
    },
  },
};
</script>
