<template>
  <div
    :class="
      'watch-later-box animate__animated ' + animateClass + ' animate__faster'
    "
    ref="dropdown"
  >
    <span class="watch-later-box__close icon-close" @click="close"></span>
    <swiper-list
      title="Watch Later Videos"
      :items="watch_later"
      bgColor="transparent"
      @swiperItem_clicked="close"
    />
    <div v-if="!watch_later || (watch_later && watch_later.length == 0)">
      <p>
        You have not any videos in your watch later videos list. You can add a
        video to your watch later list by clicking on the plus icon in the video
        page.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SwiperList from "@/components/home/SwiperList.vue";

export default {
  name: "WatchLaterBox",
  components: {
    SwiperList,
  },
  data() {
    return {
      animateClass: "animate__slideInDown",
    };
  },
  mounted() {
    this.fetchWatchLater();
    // add class to body to prevent scrolling
    setTimeout(() => document.body.classList.add("noscroll"), 500);
  },
  updated() {
    window.addEventListener("click", this.closeDropdown);
  },
  computed: {
    ...mapGetters("videos", ["watch_later"]),
  },
  methods: {
    ...mapActions("videos", ["setIsWatchLaterOpen", "fetchWatchLater"]),
    close() {
      // remove class from body to allow scrolling
      document.body.classList.remove("noscroll");

      this.animateClass = "animate__slideOutUp";
      setTimeout(() => this.setIsWatchLaterOpen(false), 800);
    },
    closeDropdown(e) {
      if (!this.$refs.dropdown.contains(e.target)) {
        this.close();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeDropdown);
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.watch-later-box {
  // position and layout
  position: fixed;
  z-index: 1;
  top: 104px;
  right: 0;
  left: 0;
  min-height: 50vh;
  margin: 0 10px 0 10px;

  // display and visibility
  row-gap: 16px;

  // box model
  padding: 10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  // background
  background-color: #ffffffbb;
  backdrop-filter: blur(8px);

  &__close {
    // position and layout
    position: absolute;
    top: 15px;
    right: 15px;

    // typography
    font-size: 0.8rem;
    color: black;

    // background
    cursor: pointer;
  }
}
</style>
