import { render, staticRenderFns } from "./FallbackView.vue?vue&type=template&id=9fd4deca&scoped=true"
import script from "./FallbackView.vue?vue&type=script&lang=js"
export * from "./FallbackView.vue?vue&type=script&lang=js"
import style0 from "./FallbackView.vue?vue&type=style&index=0&id=9fd4deca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fd4deca",
  null
  
)

export default component.exports