import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Keycloak from "keycloak-js";
import axios from "axios";

import "animate.css";

import Buefy from "buefy";
import { ToastProgrammatic as Toast } from "buefy";
import VueCropper from "vue-cropper";

import VueBreakpointMixin from "vue-breakpoint-mixin";
Vue.mixin(VueBreakpointMixin);

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas, far, fab);

Vue.use(Buefy);
Vue.use(VueCropper);

import VueMatomo from "vue-matomo";

Vue.config.productionTip = false;

// Sentry
import * as Sentry from "@sentry/vue";
import Package from "../package.json";

const mount = () => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
};

const init = async () => {
  try {
    const config = (await axios.get("/config.json", { baseURL: "" })).data;

    // Start configure Matomo
    Vue.use(VueMatomo, {
      host: config.matomo.host,
      siteId: config.matomo.siteId,
      trackerFileName: "matomo",
      router: router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: false,
      requireCookieConsent: false,
      enableHeartBeatTimer: false,
      heartBeatTimerInterval: 15,
      debug: false,
      userId: undefined,
      cookieDomain: undefined,
      domains: undefined,
      preInitActions: [],
      trackSiteSearch: false,
      crossOrigin: undefined,
    });
    // End configure Matomo

    axios.defaults.baseURL = config.apiUrl;
    const keycloak = Keycloak(config.keycloak);

    // Sentry
    if (config.sentry.enabled === true) {
      Sentry.init({
        Vue,
        dsn: config.sentry.dsn,
        environment: config.sentry.environment,
        release: `${Package.name}@${Package.version}`,
        tracesSampleRate: config.sentry.tracesSampleRate,
        integrations: [
          new Sentry.BrowserTracing({
            tracePropagationTargets: config.sentry.tracePropagationTargets,
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Replay(),
        ],
        replaysSessionSampleRate: config.sentry.replaysSessionSampleRate,
        replaysOnErrorSampleRate: config.sentry.replaysOnErrorSampleRate,
      });
    }

    keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
      })
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          axios.interceptors.request.use(
            (config) => {
              if (config.headers && keycloak.token !== undefined) {
                if (
                  config.baseURL === Vue.prototype.$config.picksUserUrl ||
                  config.baseURL === Vue.prototype.$config.sisNotifyUrl
                ) {
                  config.headers["Authorization"] = `Bearer ${keycloak.token}`;
                } else {
                  config.headers["X-Auth-Token"] = keycloak.token;
                }
              }
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );
          axios
            .post(`${Vue.prototype.$config.apiUrl}/user/register`)
            .catch((error) => {
              console.log("Registration failed:", error.message);
              Toast.open({
                duration: 3000,
                message: "Registration failed",
                position: "is-top-right",
                type: "is-danger",
              });

              mount();
              router.push({ name: "fallback" });
            });
        }

        setInterval(() => {
          if (keycloak?.authenticated) {
            keycloak.updateToken(70).catch((error) => {
              console.log("Token refresh failed:", error.message);
            });
          }
        }, 60000);

        mount();
      })
      .catch((error) => {
        console.log("Authentication failed:", error.message);
        Toast.open({
          duration: 3000,
          message: "Authentication failed",
          position: "is-top-right",
          type: "is-danger",
        });

        mount();
        router.push({ name: "fallback" });
      });

    Vue.prototype.$keycloak = keycloak;
    Vue.prototype.$config = config;
  } catch (error) {
    console.log(error);
    Toast.open({
      duration: 3000,
      message: "Init App failed",
      position: "is-top-right",
      type: "is-danger",
    });

    mount();
    router.push({ name: "fallback" });
  }
};

init();
