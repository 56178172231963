import Vue from "vue";
import axios from "axios";

const state = () => ({
  author: null,
});
const getters = {
  author: (state) => JSON.parse(JSON.stringify(state.author)),
};
const actions = {
  fetchAuthor({ commit }, username) {
    axios
      .get(`${Vue.prototype.$config.apiUrl}/user/info/${username}`)
      .then((response) => {
        commit("setAuthor", response.data);
      });
  },
};
const mutations = {
  setAuthor(state, author) {
    state.author = JSON.parse(JSON.stringify(author));
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
