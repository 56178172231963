<template>
  <div class="search-bar" @click="focusInput">
    <span
      class="icon-trash"
      v-if="tags && tags.length > 0"
      @click="() => $emit('clearAll')"
    ></span>
    <tag-item
      v-for="(tag, i) in tags"
      :key="i"
      :tag="tag"
      :isDeletable="true"
      @delete="(data) => $emit('deleteTag', data)"
    />
    <b-input
      v-model="searchText"
      placeholder="What do you want to see now?"
      icon-pack="fas"
      icon="search"
      @input="getSearchHints"
      class="search-bar__input"
      ref="search-bar__input"
      @keyup.enter.native="onEnter"
    >
    </b-input>
    <div class="search-bar__actions">
      <span
        class="icon-x"
        v-if="searchText?.trim().length > 0"
        @click="clearSearchInput"
      ></span>
      <span
        class="icon-send"
        v-if="tags && tags.length > 0"
        @click="$emit('triggerSearch')"
      ></span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "lodash/debounce";

import TagItem from "@/components/home/search/TagItem.vue";

export default {
  name: "SearchBar",
  components: {
    TagItem,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchText: "",
    };
  },
  mounted() {
    // set focus on input
    this.focusInput();
  },
  methods: {
    getSearchHints: debounce(function (word) {
      if (word.length < 3) {
        this.$emit("receiveHints", {});
        return;
      }

      axios
        .get(`${this.$config.apiUrl}/search/hints/${word}`)
        .then((response) => {
          this.$emit("receiveHints", response.data);
        });
    }, 500),
    focusInput() {
      this.$refs["search-bar__input"].focus();
    },
    clearSearchInput() {
      this.searchText = "";
    },
    onEnter() {
      if (this.tags && this.tags.length > 0) {
        this.$emit("triggerSearch");
      }
    },
  },
  watch: {
    tags: {
      handler: function () {
        // restore focus on input
        this.focusInput();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  // position and layout
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 0.3rem;

  // display and visibility
  display: flex;

  // box model
  padding: 4px 10px;
  border: 1px solid hsl(0, 0%, 86%);
  border-radius: 12px;
  width: 80%;
  min-width: 200px;

  // background
  background-color: white;
  cursor: text;

  &__input {
    width: 80%;
  }

  &__input::v-deep input {
    border: none !important;
    box-shadow: none !important;
  }

  &__input::v-deep .input:focus {
    box-shadow: none !important;
  }

  &__actions {
    // position and layout
    column-gap: 1rem;

    // display and visibility
    display: flex;

    // box model
    margin-left: auto;
  }
}

.icon-trash {
  // box model
  margin-right: 1rem;

  &:before {
    // box model
    border-radius: 50%;
    padding: 4px;

    // background
    background-color: #d9d9d9;
  }
}

span[class^="icon-"] {
  cursor: pointer;
}
</style>
