var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"swiperList",staticClass:"swipercontainer",class:{
    'swipercontainer-boxed': _vm.isBoxed,
    'swipercontainer-compact': _vm.isCompact,
    'swipercontainer-search': _vm.isInSearch,
  }},[_c('div',{staticClass:"swipertop",class:{ 'swipertop-search': _vm.isInSearch }},[_c('div',[_c('span',{staticClass:"swipertop-title"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_vm._t("title"),_c('b-tooltip',{attrs:{"label":_vm.tooltipLabel,"position":"is-bottom","type":"is-dark"}},[(_vm.editable)?_c('div',{staticClass:"swipertop-icon icon-plus-square",on:{"click":function($event){return _vm.openModal()}}}):_vm._e()])],2),(_vm.description)?_c('div',[_c('span',{staticClass:"swipertop-description"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]):_vm._e()]),_c('div',{staticClass:"swiperlist",class:{ 'swiperlist-search': _vm.isInSearch },attrs:{"id":_vm.id}},[(!_vm.hideLeft)?_c('div',{staticClass:"swiperlist-arrow swiperlist-arrow-left",class:{
        'swiperlist-arrow-left-boxed': _vm.isBoxed,
        'swiperlist-arrow-left--season': _vm.type === 'season',
      }},[_c('span',{staticClass:"swiperlist-arrow-icon icon-keyboard_arrow_left",class:{
          'swiperlist-arrow-icon--season': _vm.type === 'season',
        },on:{"click":_vm.scrollLeft}})]):_vm._e(),(_vm.type === 'video' || _vm.type === 'episode')?_vm._l((_vm.items),function(item,i){return _c('swiper-item-video',{key:i,attrs:{"video":item,"clickable":_vm.isSwiperItemClickable,"selectable":_vm.isSwiperItemSelectable,"type":_vm.type},on:{"videoItem_clicked":function($event){return _vm.$emit('swiperItem_clicked', $event)}}})}):_vm._e(),(_vm.type === 'series')?_vm._l((_vm.items),function(item,i){return _c('swiper-item-serie',{key:i,attrs:{"serie":item,"clickable":_vm.isSwiperItemClickable,"selectable":_vm.isSwiperItemSelectable},on:{"serieItem_clicked":function($event){return _vm.$emit('swiperItem_clicked', $event)}}})}):_vm._e(),(_vm.type === 'season')?_vm._l((_vm.items),function(item,i){return _c('swiper-item-season',{key:i,attrs:{"season":item,"selectable":_vm.isSwiperItemSelectable}})}):_vm._e(),(!_vm.hideRight)?_c('div',{staticClass:"swiperlist-arrow swiperlist-arrow-right",class:{
        'swiperlist-arrow-right-boxed': _vm.isBoxed,
        'swiperlist-arrow-right--season': _vm.type === 'season',
      }},[_c('span',{staticClass:"swiperlist-arrow-icon icon-keyboard_arrow_right",class:{
          'swiperlist-arrow-icon--season': _vm.type === 'season',
        },on:{"click":_vm.scrollRight}})]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }