<template>
  <div class="shadow-background"></div>
</template>

<script>
export default {
  name: "ShadowBackground",
};
</script>

<style lang="scss" scoped>
.shadow-background {
  // position and layout
  position: absolute;
  left: 0px;
  top: -1px;
  pointer-events: none;

  // box model
  box-shadow: 0px 4px 40px 23px rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 80vh;
}
</style>
