<template>
  <div class="people" :class="{ 'is-actor': isActor }">
    <div class="people-avatar" @click="navigateToProfile">
      <user-avatar :size="200" :username="item.username" />
    </div>
    <div class="people-name" @click="navigateToProfile">
      {{ displayName }}
    </div>
    <div class="people-info">
      <span class="icon-film"></span>
      <span class="people-info-text">
        {{ item.total_videos || 1 }}
        {{ (item.total_videos || 1) === 1 ? "video" : "videos" }}
      </span>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";

export default {
  name: "PeopleItem",
  components: {
    UserAvatar,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isActor: {
      type: Boolean,
      default: false,
    },
    peopleType: {
      type: String,
      required: true,
      validator: (value) => ["actor", "author"].includes(value),
    },
  },
  computed: {
    displayName() {
      return this.isActor
        ? this.item.casting
        : `${this.item.name} ${this.item.surname || ""}`.trim();
    },
  },
  methods: {
    navigateToProfile() {
      if (this.isActor) {
        this.$router.push({
          name: "actor",
          params: { name: this.displayName },
        });
      } else {
        this.$router.push({
          name: "author",
          params: { username: this.item.username },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.people {
  // position and layout
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  // display and visibility
  display: flex;

  // box model
  width: 193.15px;
  padding: 0px;

  &-avatar {
    // background
    cursor: pointer;
  }

  &.is-actor {
    // Add any specific styles for actors here if needed
  }

  &-name {
    // box model
    width: 209px;
    margin-top: 25px;

    // clipping
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    // background
    cursor: pointer;

    // typography
    font-size: 25px;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    letter-spacing: 1px;
    color: #000000;
  }

  &-info {
    // New styles for author-info
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;

    // typography
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #000000;

    .icon-film {
      font-size: 18px;
    }
  }
}
</style>
