<template>
  <div class="promotional">
    <b-carousel
      :indicator-inside="true"
      icon-pack="fas"
      :pause-info="false"
      :arrow-hover="false"
      icon-size="is-medium"
      icon-prev="caret-left"
      icon-next="caret-right"
      :indicator-background="true"
      @change="handleChange"
    >
      <b-carousel-item v-for="(video, i) in promotional" :key="i">
        <b-image
          ratio="16by9"
          class="image"
          :src="video.base64"
          id="promotional__carousel__item__image"
        ></b-image>
      </b-carousel-item>
    </b-carousel>
    <overlay-box
      v-if="promotional && promotional.length > 0"
      :video="promotional[index]"
      :editable="editable"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import OverlayBox from "@/components/home/carousel/OverlayBox.vue";

export default {
  name: "PromotionalCarousel",
  components: {
    OverlayBox,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  async created() {
    await this.fetchPromotional();
  },
  computed: {
    ...mapGetters("videos", ["promotional"]),
  },
  methods: {
    ...mapActions("videos", ["fetchPromotional"]),
    handleChange(value) {
      this.index = value;
      // console.log(this.promotional);
    },
  },
};
</script>

<style lang="scss" scoped>
.promotional {
  position: relative;
}

#promotional__carousel__item__image {
  // box model
  height: 80vh;
  padding: 0;
}
</style>
