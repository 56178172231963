<template>
  <div class="searchpage">
    <div class="searchpage__tags">
      <div class="searchpage__tags__title">Search results for:</div>
      <div class="searchpage__tags__items">
        <tag-item v-for="(tag, i) in tags" :key="i" :tag="tag" />
      </div>
    </div>
    <div
      class="searchpage__results"
      v-if="
        !isSearchBoxOpen && tags && tags.length > 0 && data && data.length > 0
      "
    >
      <div v-for="(el, i) in data" :key="i">
        <swiper-list
          v-if="el.videos && el.videos.length > 0"
          :items="el.videos"
          :title="getTitle(JSON.parse(tags[i])?.type, true)"
        >
          <template v-slot:title><tag-item :tag="tags[i]" /></template>
        </swiper-list>
        <swiper-list
          v-if="el.series && el.series.length > 0"
          :title="getTitle(JSON.parse(tags[i])?.type, false)"
          :items="el.series"
          type="series"
        >
          <template v-slot:title><tag-item :tag="tags[i]" /></template>
        </swiper-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TagItem from "@/components/home/search/TagItem.vue";
import SwiperList from "@/components/home/SwiperList.vue";

export default {
  name: "SearchView",
  components: {
    TagItem,
    SwiperList,
  },
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.fillData();
  },
  computed: {
    ...mapGetters("search", ["tags", "search_results", "isSearchBoxOpen"]),
  },
  methods: {
    fillData() {
      this.data = [];

      this.tags.forEach((tag) => {
        tag = JSON.parse(tag);
        var obj = {};

        if (!this.search_results[tag.type]) return;

        switch (tag.type) {
          case "authors":
            obj = this.search_results["authors"].find(
              (author) => author.id == tag.id
            );
            this.data.push(JSON.parse(JSON.stringify(obj)));
            break;
          case "castings":
            obj = this.search_results["castings"].find(
              (casting) => casting.casting == tag.tag
            );
            this.data.push(JSON.parse(JSON.stringify(obj)));
            break;
          case "hashtags":
            obj = this.search_results["hashtags"].find(
              (hashtag) => hashtag.hashtag == tag.tag
            );
            this.data.push(JSON.parse(JSON.stringify(obj)));
            break;
          case "series":
            obj = this.search_results["series"].find(
              (serie) => serie.id == tag.id
            );
            this.data.push({ series: [JSON.parse(JSON.stringify(obj))] });
            break;
          case "videos":
            obj = this.search_results["videos"].find(
              (video) => video.id == tag.id
            );
            this.data.push({ videos: [JSON.parse(JSON.stringify(obj))] });
            break;
          case "categories":
            obj = this.search_results["categories"].find(
              (category) => category.id == tag.id
            );
            this.data.push(JSON.parse(JSON.stringify(obj)));
            break;
        }
      });
    },
    getTitle(tagType, isVideo) {
      switch (tagType) {
        case "authors":
          return isVideo ? "Videos by " : "Series by ";
        case "castings":
          return "Videos with ";
        case "hashtags":
          return "Videos tagged with ";
        case "series":
          return "Serie with title ";
        case "videos":
          return "Video with title ";
        case "categories":
          return isVideo ? "Videos with category " : "Series with category ";
        default:
          return "";
      }
    },
  },
  watch: {
    search_results() {
      this.fillData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.searchpage {
  &__tags {
    // position and layout
    flex-direction: column;
    row-gap: 1rem;

    // display and visibility
    display: flex;

    // box model
    padding: 175px 40px 40px 40px;

    &__title {
      // typography
      font-size: 3rem;
      font-weight: 700;
      color: #98a6ae;
    }

    &__items {
      // position and layout
      flex-wrap: wrap;
      row-gap: 0.5rem;
      column-gap: 0.5rem;

      // display and visibility
      display: flex;
    }
  }
}

@include mobile {
  .searchpage {
    &__tags {
      &__title {
        // typography
        font-size: 2rem;
      }
    }
  }
}
</style>
