var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-container"},[_c('img',{class:`swiper-container-image ${!_vm.clickable ? 'cursor-default' : ''}`,attrs:{"src":_vm.image,"alt":_vm.video.title},on:{"click":function($event){_vm.goToDetailsPage(), _vm.$emit('videoItem_clicked')}}}),(_vm.selectable)?_c('div',{staticClass:"swiper-container-upper-icons"},[_c('b-tooltip',{attrs:{"label":"Image","position":"is-bottom","type":"is-dark"}},[(_vm.type === 'video')?_c('div',{staticClass:"swiper-container-upper-icons-icon icon-image",on:{"click":_vm.openCropperModal}}):_vm._e()]),_c('b-tooltip',{attrs:{"label":"Edit","position":"is-bottom","type":"is-dark"}},[_c('div',{staticClass:"swiper-container-upper-icons-icon icon-pencil",on:{"click":_vm.openModal}})])],1):_vm._e(),(!_vm.selectable && (_vm.video.is_watched || _vm.video.is_watch_later))?_c('div',{staticClass:"swiper-container-icons"},[(_vm.video.is_watched)?_c('div',[_c('span',{staticClass:"swiper-container-icons-icon icon-eye"})]):_vm._e(),(_vm.video.is_watch_later)?_c('div',[_c('span',{staticClass:"swiper-container-icons-icon icon-clock"})]):_vm._e()]):_vm._e(),(!_vm.selectable)?_c('div',{staticClass:"swiper-container-hover-icons"},[_c('b-tooltip',{attrs:{"label":"Like","position":"is-bottom","type":"is-dark"}},[_c('div',{class:[
            'swiper-container-hover-icons-icon',
            {
              'is-active icon-thumbsup_fill': _vm.localVideo.is_liked,
              'icon-thumbsup': !_vm.localVideo.is_liked,
            },
          ],on:{"click":function($event){$event.stopPropagation();return _vm.handleLikeClick.apply(null, arguments)}}})]),_c('b-tooltip',{attrs:{"label":"Add to Watchlist","position":"is-bottom","type":"is-dark"}},[_c('div',{staticClass:"swiper-container-hover-icons-icon",class:{ 'is-active': _vm.localVideo.is_watch_later },on:{"click":function($event){$event.stopPropagation();return _vm.handleWatchLaterClick.apply(null, arguments)}}},[_c('i',{class:_vm.localVideo.is_watch_later ? 'icon-check' : 'icon-clock'})])])],1):_vm._e()]),_c('div',{staticClass:"swiper-info"},[_c('span',{class:`swiper-info-text-title ${!_vm.clickable ? 'cursor-default' : ''}`,on:{"click":_vm.goToAuthorPage}},[_c('user-avatar',{attrs:{"size":50,"username":_vm.video.owner?.username}})],1),_c('div',{staticClass:"swiper-info-text"},[_c('span',{class:`swiper-info-text-title ${
          !_vm.clickable ? 'cursor-default' : ''
        }`,on:{"click":function($event){return _vm.goToDetailsPage()}}},[_vm._v(" "+_vm._s(_vm.video.title)+" ")]),_c('span',{staticClass:"swiper-info-text-author"},[_vm._v(_vm._s(_vm.video.owner?.name)+" "+_vm._s(_vm.video.owner?.surname))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }