<template>
  <div>
    <div v-if="!isSerie">
      <div class="cover-image" v-if="!play" @click="playVideo">
        <img
          :src="computedImage"
          alt="video image"
          class="cover-image__image"
        />
        <img src="/assets/icons/play.svg" class="cover-image__play" />
        <div class="cover-image--overlay"></div>
      </div>
      <iframe
        v-else
        allow="fullscreen;autoplay;"
        :src="videoUrl + '?autoplay=1&rel=0'"
        class="cover-image"
      ></iframe>
    </div>
    <div v-if="isSerie">
      <div class="cover-image">
        <img
          :src="computedImage"
          alt="series image"
          class="cover-image__image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import utils from "@/utils/utils";

export default {
  name: "CoverImage",
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    videoUrl: {
      type: String,
      required: false,
    },
    videoId: {
      type: String,
      required: false,
    },
    isSerie: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    imageUrl: {
      handler: async function () {
        this.image = await utils.getImage(this.imageUrl || null);
      },
      immediate: true,
    },
  },
  computed: {
    computedImage() {
      return this.image || "/assets/images/default.webp";
    },
  },
  data() {
    return {
      play: false,
      image: null,
    };
  },
  methods: {
    playVideo() {
      this.play = true;
      // set video as "watched"
      axios.put(`${this.$config.apiUrl}/video/watched/${this.videoId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.cover-image {
  // position and layout
  position: relative;

  // box model
  border-radius: 8px;
  width: 864px;
  height: 486px;
  max-width: unset;

  // background
  cursor: pointer;

  &__image {
    // position and layout
    vertical-align: middle;

    // box model
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__play {
    // position and layout
    position: absolute;
    left: calc(50% - 90px);
    top: calc(50% - 90px);
    filter: drop-shadow(3px 5px 5px rgb(0 0 0 / 1))
      drop-shadow(0px 0px 50px rgb(0 0 0 / 0.5));
    opacity: 0.9;
    transition: 0.3s all;
  }

  &__play:hover {
    opacity: 1;
  }

  &--overlay {
    // position and layout
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    // box model
    width: 100%;
    height: 100%;
    border-radius: 8px;

    // background
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  }
}

@include until-widescreen {
  .cover-image {
    // box model
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;

    &__play {
      // clipping
      transform: scale(0.5);
    }
  }
}
</style>
