import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import DetailSerieView from "../views/DetailSerieView.vue";
import DetailVideoView from "../views/DetailVideoView.vue";
import AuthorPageView from "../views/AuthorPageView.vue";
import ActorPageView from "../views/ActorPageView.vue";
import SearchView from "../views/SearchView.vue";
import MyContentView from "../views/MyContentView.vue";
import PromotionalVideosView from "../views/PromotionalVideosView.vue";
import FallbackView from "../views/FallbackView.vue";
import TheFaqView from "../views/TheFaqView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/video/:video_id",
    name: "video",
    component: DetailVideoView,
  },
  {
    path: "/series/:series_id",
    name: "series",
    component: DetailSerieView,
  },
  {
    path: "/user/:username",
    name: "author",
    component: AuthorPageView,
  },
  {
    path: "/actor/:name",
    name: "actor",
    component: ActorPageView,
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
  },
  {
    path: "/my-content",
    name: "my-content",
    component: MyContentView,
  },
  {
    path: "/promotional-videos",
    name: "promotional-videos",
    component: PromotionalVideosView,
  },
  {
    path: "/faq",
    name: "faq",
    component: TheFaqView,
  },
  {
    path: "*",
    name: "fallback",
    component: FallbackView,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    // scroll to top of the page
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
});

export default router;
