<template>
  <div class="faq">
    <div class="faq__title">Frequently Asked Questions</div>
    <div class="faq__body">
      <b-collapse
        class="card"
        animation="slide"
        v-for="(collapse, index) of collapses"
        :key="index"
        :open="isOpen == index"
        @open="isOpen = index"
        :aria-id="'contentIdForA11y5-' + index"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            :aria-controls="'contentIdForA11y5-' + index"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">
              {{ collapse.title }}
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content" v-html="collapse.text"></div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import FaqData from "@/json/faqdata.json";

export default {
  name: "FaqView",
  data() {
    return {
      collapses: FaqData,
      isOpen: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.faq {
  // box model
  padding: 175px 0 0 0;
  padding-left: calc(4rem - 8px);

  &__title {
    //box model

    padding-bottom: 2rem;

    // typography
    color: black;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 2.25rem;
  }

  &__body {
    //box model

    padding-right: 3rem;
    padding-bottom: 3rem;
  }
}
</style>
