<template>
  <div class="mycontent">
    <impersonate-user v-if="isAdmin" />
    <admin-modal v-if="isAdmin" />
    <user-info
      v-if="isAdmin ? impersonated_user : true"
      :username="
        isAdmin ? impersonated_user : $keycloak.tokenParsed?.preferred_username
      "
    />
    <div class="mycontent_videos">
      <swiper-list
        title="My Videos"
        :items="my_videos"
        type="video"
        :editable="true"
        tooltipLabel="Add New Video"
      />
    </div>
    <div class="mycontent_series">
      <swiper-list
        title="My Series"
        :items="my_series"
        type="series"
        :editable="true"
        tooltipLabel="Add New Serie"
      />
    </div>
    <div class="mycontent_seasons">
      <swiper-list
        v-if="selected_serie && selected_serie.seasonsWithVideos"
        :title="`Seasons of ${selected_serie.title}`"
        :items="selected_serie.seasonsWithVideos"
        type="season"
        :editable="true"
        tooltipLabel="Add New Season"
      />
    </div>
    <div class="mycontent_episodes">
      <swiper-list
        v-if="selected_season && selected_season.episodesWithVideos"
        :title="`Episodes of Season ${selected_season.number}`"
        :items="selected_season.episodesWithVideos"
        type="episode"
        :editable="true"
        tooltipLabel="Add New Episode"
      />
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import SwiperList from "@/components/home/SwiperList.vue";
import UserInfo from "@/components/shared/UserInfo.vue";
import AdminModal from "@/components/modal/AdminModal.vue";
import ImpersonateUser from "@/components/admin/ImpersonateUser.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "MyContentView",
  components: {
    SwiperList,
    UserInfo,
    AdminModal,
    ImpersonateUser,
  },
  mounted() {
    this.fetchMyVideos(this.$keycloak.tokenParsed.preferred_username);
    this.fetchMySeries(this.$keycloak.tokenParsed.preferred_username);
  },
  computed: {
    ...mapGetters("videos", ["my_videos"]),
    ...mapGetters("series", ["my_series", "selected_serie"]),
    ...mapGetters("seasons", ["selected_season"]),
    ...mapGetters("admin", ["impersonated_user"]),
    isAdmin() {
      return utils.isAdmin();
    },
  },
  methods: {
    ...mapActions("videos", ["fetchMyVideos"]),
    ...mapActions("series", ["fetchMySeries"]),
  },
  watch: {
    impersonated_user() {
      this.fetchMyVideos(this.impersonated_user);
      this.fetchMySeries(this.impersonated_user);
    },
  },
};
</script>

<style lang="scss" scoped>
.mycontent {
  // box model
  padding: 175px 0 0 0;
}
</style>
