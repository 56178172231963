<template>
  <div class="shadow">
    <search-box v-if="isSearchBoxOpen" />
    <watch-later-box v-if="isWatchLaterOpen" />
    <liked-box v-if="isLikedOpen" />
    <b-navbar
      :close-on-click="false"
      :class="{
        navbar__noedge: isWatchLaterOpen || isLikedOpen || isSearchBoxOpen,
      }"
    >
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            src="/assets/images/p42_logo.png"
            alt="Project42"
            class="navbar-logo"
          />
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div" :class="isDesktop ? '' : 'custom-burger'">
          <b-tooltip label="Search" position="is-bottom" type="is-dark">
            <button-icon
              iconSide="right"
              iconClass="icon-search"
              @click="openSearch"
              iconSize="21px"
              class="navbar-icon"
              text=""
            />
          </b-tooltip>
          <b-tooltip
            label="Liked videos list"
            position="is-bottom"
            type="is-dark"
          >
            <div class="navbar-icon icon-favourites" @click="openLiked"></div>
          </b-tooltip>
          <b-tooltip
            label="Watch later videos list"
            position="is-bottom"
            type="is-dark"
          >
            <div class="navbar-icon icon-clock" @click="openWatchLater"></div>
          </b-tooltip>

          <b-dropdown
            :triggers="['click']"
            aria-role="list"
            position="is-bottom-left"
            :mobile-modal="false"
            @active-change="openMenu"
            id="navbar-avatar"
          >
            <template #trigger>
              <b-tooltip label="Menu" position="is-bottom" type="is-dark">
                <user-avatar
                  :size="50"
                  :username="$keycloak.tokenParsed?.preferred_username"
                  class="navbar-profile"
                />
              </b-tooltip>
            </template>
            <b-dropdown-item custom aria-role="menuitem">
              Logged as <b>{{ $keycloak.tokenParsed?.preferred_username }}</b>
            </b-dropdown-item>
            <hr class="dropdown-divider" />
            <b-dropdown-item aria-role="listitem" @click.native="openMyContent">
              <span class="icon-profile"></span>
              {{ contentTitle }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isAdmin"
              aria-role="listitem"
              @click.native="$router.push({ name: 'promotional-videos' })"
            >
              <span class="icon-bullhorn"></span>
              Promotional Videos
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click.native="openFaq">
              <span class="icon-lifebuoy"></span>
              FAQ
            </b-dropdown-item>
            <hr class="dropdown-divider" />
            <b-dropdown-item
              aria-role="listitem"
              @click.native="$keycloak.logout"
            >
              <span class="icon-logout"></span>
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import SearchBox from "@/components/home/search/SearchBox.vue";
import WatchLaterBox from "@/components/home/video/WatchLaterBox.vue";
import LikedBox from "@/components/home/video/LikedBox.vue";
import utils from "@/utils/utils";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavBar",
  components: {
    UserAvatar,
    ButtonIcon,
    SearchBox,
    WatchLaterBox,
    LikedBox,
  },
  computed: {
    ...mapGetters("search", ["isSearchBoxOpen"]),
    ...mapGetters("videos", ["isWatchLaterOpen", "isLikedOpen"]),
    isAdmin() {
      return utils.isAdmin();
    },
    contentTitle() {
      return this.isAdmin ? "Manage Content" : "My Profile";
    },
  },
  methods: {
    ...mapActions("search", ["setIsSearchBoxOpen"]),
    ...mapActions("videos", ["setIsWatchLaterOpen", "setIsLikedOpen"]),
    openSearch() {
      this.setIsSearchBoxOpen(!this.isSearchBoxOpen);
      this.$matomo?.trackEvent("search", "open");
    },
    openLiked() {
      this.setIsLikedOpen(!this.isLikedOpen);
      this.$matomo?.trackEvent("liked_videos", "open");
    },
    openWatchLater() {
      this.setIsWatchLaterOpen(!this.isWatchLaterOpen);
      this.$matomo?.trackEvent("watch_later_videos", "open");
    },
    openMenu($event) {
      if ($event == true) {
        this.$matomo?.trackEvent("menu", "open");
      }
    },
    openMyContent() {
      this.$matomo?.trackEvent("my-content", "open");
      this.$router.push({ name: "my-content" });
    },
    openFaq() {
      this.$matomo?.trackEvent("faq", "open");
      this.$router.push({ name: "faq" });
    },
  },
};
</script>

<style lang="scss" scoped>
.shadow {
  // position and layout
  position: absolute;
  z-index: 1;
  left: 0px;
  top: -1px;

  // box model
  width: 100vw;
  height: 180px;

  // background
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 180px);
}

.navbar {
  // position and layout
  position: absolute;
  z-index: 2;

  // box model
  margin: 15px 10px 0 10px;
  border-radius: 8px;
  width: -moz-available;
  width: -webkit-fill-available;

  // background
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    rgba(255, 255, 255, 0.5);

  &-logo {
    padding-left: 5px;
  }

  &__noedge {
    border-radius: 8px 8px 0 0;
  }

  &-end .navbar-item {
    column-gap: 2.1875rem;

    &.custom-burger {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  }

  &-icon {
    // background
    cursor: pointer;

    // typography
    color: #172126;
    font-size: 21px;

    &:hover {
      // animation
      transform: scale(1.15);
    }
  }

  &-profile {
    // background
    cursor: pointer;
  }
}

#navbar-avatar {
  max-height: 50px;
}
.navbar-profile {
  transition: all 0.2s;
}
.navbar-profile:hover {
  transform: scale(1.1);
}
</style>
