<template>
  <span class="tag-item" :style="`background-color: ${tagBgColor}`">
    <img :src="tagIcon" class="tagicon" />
    <span class="tagtext" :style="`color: ${tagColor}`">
      {{ JSON.parse(tag)?.tag }}
    </span>
    <a
      v-if="isDeletable"
      role="button"
      class="delete is-small"
      @click="() => $emit('delete', tag)"
    ></a>
  </span>
</template>

<script>
export default {
  name: "TagItem",
  props: {
    tag: {
      type: String,
      required: true,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tagsData: {
        authors: {
          iconUrl: "/assets/icons/user.svg",
          color: "#FFFFFF",
          backgroundColor: "#FB8D4B",
        },
        castings: {
          iconUrl: "/assets/icons/users.svg",
          color: "#FFFFFF",
          backgroundColor: "#4BA6FB",
        },
        categories: {
          iconUrl: "/assets/icons/tag.svg",
          color: "#5A6972",
          backgroundColor: "#C3FB4B",
        },
        hashtags: {
          iconUrl: "/assets/icons/hashtag.svg",
          color: "#FFFFFF",
          backgroundColor: "#FB4B75",
        },
        videos: {
          iconUrl: "/assets/icons/video.svg",
          color: "#FFFFFF",
          backgroundColor: "#A34BFB",
        },
        series: {
          iconUrl: "/assets/icons/layers.svg",
          color: "#FFFFFF",
          backgroundColor: "#4F4BFB",
        },
        default: {
          iconUrl: "",
          color: "#5A6972",
          backgroundColor: "#D9D9D9",
        },
      },
    };
  },
  computed: {
    tagIcon() {
      return (
        this.tagsData[JSON.parse(this.tag)?.type]?.iconUrl ||
        this.tagsData["default"].iconUrl
      );
    },
    tagColor() {
      return (
        this.tagsData[JSON.parse(this.tag)?.type]?.color ||
        this.tagsData["default"].color
      );
    },
    tagBgColor() {
      return (
        this.tagsData[JSON.parse(this.tag)?.type]?.backgroundColor ||
        this.tagsData["default"].color
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-item {
  // box model
  margin-right: 0.275rem;
  height: calc(2em - 1px);
  max-width: calc(100% - 8px);
  width: fit-content;

  // typography
  font-size: 0.9em;

  &:not(body) {
    // position and layout
    align-items: center;
    justify-content: center;

    // display and visibility
    display: inline-flex;

    // box model
    padding: 5px 10px;
    border-radius: 20px;
    height: 2rem;

    // background
    background-color: hsl(0, 0%, 96%);

    // typography
    line-height: 1.5;
    white-space: nowrap;
    color: hsl(0, 0%, 29%);

    .tagicon {
      // box model
      margin-right: 0.25rem;
    }

    .tagtext {
      // typography
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .delete {
      // box model
      margin-left: 0.25rem;
    }
  }
  svg {
    fill: #fff !important;
    stroke: #fff !important;
  }
}
</style>
