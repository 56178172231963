const state = () => ({
  open: false,
  item: null,
  type: "",
  episode_number: null,
});
const getters = {
  open: (state) => state.open,
  item: (state) => JSON.parse(JSON.stringify(state.item)),
  type: (state) => state.type,
  episode_number: (state) => state.episode_number,
};
const actions = {
  setOpen({ commit }, open) {
    commit("setOpen", open);
  },
  setItem({ commit }, item) {
    commit("setItem", item);
  },
  setType({ commit }, type) {
    commit("setType", type);
  },
  setEpisodeNumber({ commit }, episode_number) {
    commit("setEpisodeNumber", episode_number);
  },
};
const mutations = {
  setOpen(state, open) {
    state.open = open;
  },
  setItem(state, item) {
    state.item = JSON.parse(JSON.stringify(item));
  },
  setType(state, type) {
    state.type = type;
  },
  setEpisodeNumber(state, episode_number) {
    state.episode_number = episode_number;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
