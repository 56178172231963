import { render, staticRenderFns } from "./FeedbackModal.vue?vue&type=template&id=8cde3826&scoped=true"
import script from "./FeedbackModal.vue?vue&type=script&lang=js"
export * from "./FeedbackModal.vue?vue&type=script&lang=js"
import style0 from "./FeedbackModal.vue?vue&type=style&index=0&id=8cde3826&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cde3826",
  null
  
)

export default component.exports