<template>
  <div class="modal-card" style="width: auto">
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
    </b-loading>
    <header class="modal-card-head">
      <h2 class="modal-card-title">Your feedback matters!</h2>
      <span
        class="modal-card-head-icon icon-x"
        @click="$emit('closeModal')"
      ></span>
    </header>
    <section class="modal-card-body">
      <p class="modal-card-text">
        Help us improve by sharing your thoughts on the app. <br />
        Let us know what you think!
      </p>
      <b-field label="Review">
        <b-rate icon-pack="fas" size="is-large" v-model="stars"></b-rate>
      </b-field>
      <b-field label="Message">
        <b-input
          maxlength="200"
          type="textarea"
          v-model="message"
          placeholder="Message"
        ></b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <div style="margin-left: auto; margin-right: 0">
        <button-icon
          @click="$emit('closeModal')"
          text="Cancel"
          iconClass="icon-x"
          iconSide="right"
          cssBackgroundColor="#FFFFFF"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="black"
        />
        <button-icon
          @click="sendFeedback"
          text="Send"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
          :disabled="stars === 0 || message === ''"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";

import { ToastProgrammatic as Toast } from "buefy";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";

import axios from "axios";

export default {
  name: "FeedbackModal",
  components: {
    ButtonIcon,
  },
  data() {
    return {
      stars: 0,
      message: "",
      isLoading: false,
    };
  },
  methods: {
    sendFeedback() {
      if (this.stars > 0 && this.text != "") {
        // Create message for wetalk
        let iconstars = "";
        for (let i = 0; i < this.stars; i++) {
          iconstars += ":star: ";
        }
        const feedback =
          "* Valutazione: * " + iconstars + "\n * Messaggio: * " + this.message;
        const data = {
          chat: {
            text: feedback,
            to: ["project42-feedback"], // for local test -> to send to a person: "@username" group: "groupName"
          },
        };

        // Send message
        this.isLoading = true;
        axios
          .post(`/`, data, { baseURL: Vue.prototype.$config.sisNotifyUrl })
          .then(() => {
            // reset variables
            this.stars = 0;
            this.text = "";
            this.isLoading = false;
            // show success notification
            Toast.open({
              duration: 3000,
              message: "Thank you for your feedback! :)",
              type: "is-success",
            });
            // close modal
            this.$emit("closeModal");
          })
          .catch((error) => {
            console.log("Errore:", error.message);
            // stop loading
            this.isLoading = false;
            // show fail notification
            Toast.open({
              duration: 3000,
              message: "Ops! Something went wrong :(",
              type: "is-danger",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  &-title {
    // typography
    line-height: unset;
    font-weight: bold;
  }

  &-text {
    // typography
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 2rem;
  }
}
</style>
