<template>
  <div class="userinfo">
    <div class="userinfo__image" v-if="author">
      <user-avatar :size="248" :username="author.username" />
    </div>
    <div class="userinfo__info" v-if="author">
      <div class="userinfo__info__text">
        <div class="userinfo__info__name">
          {{ author.name }} {{ author.surname }}
        </div>
        <div class="userinfo__info__username">
          {{ "@" + author.username }}
        </div>
        <div>
          <Picks :username="username" />
        </div>
      </div>
      <div class="userinfo__info__icons">
        <div class="userinfo__info__icons__box">
          <div class="userinfo__info__icons__box__count">
            {{ author.total_likes }}
          </div>
          <div class="userinfo__info__icons__box__text">
            <span>likes</span>
          </div>
        </div>
        <div class="userinfo__info__icons__box">
          <div class="userinfo__info__icons__box__count">
            {{ author.total_watches }}
          </div>
          <div class="userinfo__info__icons__box__text">
            <span>views</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import Picks from "@/components/shared/Picks.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserInfo",
  components: {
    UserAvatar,
    Picks,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchAuthor(this.username);
  },
  computed: {
    ...mapGetters("author", ["author"]),
  },
  methods: {
    ...mapActions("author", ["fetchAuthor"]),
  },
  watch: {
    username() {
      this.fetchAuthor(this.username);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.userinfo {
  // position and layout
  flex-direction: row;
  column-gap: 6rem;

  // display and visibility
  display: flex;

  //box model
  padding-left: calc(4rem);
  width: fit-content;

  &__image {
    // display and visibility
    display: flex;

    //box model
    height: 250px;
    width: 250px;
  }

  &__info {
    // position and layout
    flex-direction: column;
    row-gap: 2rem;

    // display and visibility
    display: flex;

    // typography
    color: black;

    &__text {
      // display and visibility
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;
    }

    &__name {
      // display and visibility
      display: flex;

      // typography
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 2.25rem;
    }

    &__username {
      // display and visibility
      display: flex;

      //box model
      width: 264px;

      // typography
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    &__icons {
      // position and layout
      flex-direction: rows;
      column-gap: 70px;

      //box model
      width: 264px;

      // display and visibility
      display: flex;

      // typography
      font-weight: 300;
      font-size: 1.6rem;
      line-height: 2rem;

      &__box {
        // position and layout
        flex-direction: row;
        column-gap: 0.5rem;
        align-items: baseline;

        // display and visibility
        display: flex;

        &__count {
          // display and visibility
          display: flex;

          // typography
          font-weight: 500;
          color: #172126;
        }

        &__text {
          // display and visibility
          display: flex;

          // typography
          font-weight: 300;
        }
      }
    }
  }
}

@include mobile {
  .userinfo {
    // position and layout
    flex-direction: column;
    row-gap: 2rem;
    &__info {
      &__icons {
        padding-bottom: 1.5rem;
      }
    }
  }
}
</style>
