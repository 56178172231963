<template>
  <div class="peoplecontainer">
    <div class="peopletitle">{{ title }}</div>
    <div class="peoplelist" :id="id">
      <div class="peoplelist-arrow peoplelist-arrow-left" v-if="!hideLeft">
        <span
          class="peoplelist-arrow-icon icon-keyboard_arrow_left"
          @click="scrollLeft"
        ></span>
      </div>
      <people-item
        v-for="(item, i) in filteredItems"
        :item="item"
        :isActor="peopleType === 'actor'"
        :key="i"
      />
      <div class="peoplelist-arrow peoplelist-arrow-right" v-if="!hideRight">
        <span
          class="peoplelist-arrow-icon icon-keyboard_arrow_right"
          @click="scrollRight"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PeopleItem from "@/components/shared/PeopleItem.vue";

export default {
  name: "PeopleList",
  components: {
    PeopleItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    peopleType: {
      type: String,
      required: true,
      validator: (value) => ["actor", "author"].includes(value),
    },
  },
  data() {
    return {
      id: null,
      hideLeft: true,
      hideRight: false,
    };
  },
  beforeMount() {
    this.id = "peoplelist_" + this._uid;
  },
  mounted() {
    const el = document.getElementById(this.id);

    const updateScrollArrows = () => {
      setTimeout(() => {
        if (el.offsetWidth + el.scrollLeft >= el.scrollWidth - 1) {
          this.hideRight = true;
        } else {
          this.hideRight = false;
        }

        if (el.scrollLeft == 0) {
          this.hideLeft = true;
        } else {
          this.hideLeft = false;
        }
      }, 500);
    };

    // check if right arrow should be hidden
    updateScrollArrows();

    addEventListener("resize", updateScrollArrows);

    el.addEventListener("scroll", updateScrollArrows);
  },
  computed: {
    ...mapGetters("videos", ["hottest_users", "hottest_actors"]),
    filteredItems() {
      if (this.peopleType === "author") {
        return this.hottest_users.filter((user) => !user.casting);
      } else if (this.peopleType === "actor") {
        return this.hottest_actors;
      }
      return [];
    },
  },
  methods: {
    ...mapActions("videos", ["fetchHottestUsers", "fetchHottestActors"]),
    scrollLeft() {
      const el = document.getElementById(this.id);

      el.scrollLeft -= 300;
      setTimeout(() => (this.hideLeft = el.scrollLeft == 0), 500);
    },
    scrollRight() {
      const el = document.getElementById(this.id);

      el.scrollLeft += 300;
      setTimeout(() => (this.hideLeft = el.scrollLeft == 0), 500);
    },
  },
  created() {
    if (this.peopleType === "author") {
      this.fetchHottestUsers();
    } else if (this.peopleType === "actor") {
      this.fetchHottestActors();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/mixins.sass";

.peoplecontainer {
  // position and layout
  flex-direction: column;
  row-gap: 40px;

  // display and visibility
  display: flex;

  // box model
  padding: 20px 8px;
}

.peopletitle {
  // box model
  padding-left: calc(4rem - 8px);

  // typography
  font-size: 2rem;
  font-weight: 700;
  color: black;
}

.peoplelist {
  // position and layout
  column-gap: 49.15px;

  // display and visibility
  display: flex;

  // clipping
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  white-space: nowrap;

  &:nth-child(2) {
    // box model
    padding-left: calc(4rem - 8px);
  }

  &-arrow {
    // position and layout
    position: absolute;
    justify-content: center;

    // display and visibility
    display: flex;

    // box model
    width: 50px;
    height: 305.47px;

    &-icon {
      // box model
      margin-top: 92.5px;
      border-radius: 50%;

      // background
      cursor: pointer;

      // typography
      font-size: 2rem;
      color: black;

      &:hover {
        // animation
        font-size: 2.2rem;
      }
    }

    &-left {
      // position and layout
      left: 0;

      // background
      background: linear-gradient(
        90deg,
        #ffffff 21.28%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &-right {
      // position and layout
      right: 0;

      // background
      background: linear-gradient(
        270deg,
        #ffffff 21.28%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}

@include mobile {
  .peoplelist {
    // clipping
    overflow-x: auto;
  }
}
</style>
