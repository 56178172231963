<template>
  <div class="ranked" @click="goToDetailsPage()">
    <div v-if="isFirst">
      <div class="ranked-number-first">
        <span class="ranked-number-first-text">
          {{ position }}
        </span>
      </div>
    </div>
    <div v-if="!isFirst">
      <div class="ranked-number">
        <span class="ranked-number-text">
          {{ position }}
        </span>
      </div>
    </div>
    <div class="ranked-image">
      <img
        class="ranked-image-item"
        :src="image"
        onerror="this.src = '/assets/images/default.webp'"
        :alt="video.title"
      />
      <div class="ranked-image-overlay">
        <span class="ranked-image-overlay-text">{{ video.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils";

export default {
  name: "RankedItem",
  props: {
    video: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isFirst() {
      return this.position === 1;
    },
  },
  data() {
    return {
      image: "/assets/images/default.webp",
    };
  },
  async created() {
    this.image = await utils.getImage(this.video.image_url_thumb);
  },
  methods: {
    goToDetailsPage() {
      this.$router.push({
        name: "video",
        params: { video_id: this.video.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  font-size: 40px;
  font-weight: bold;
  width: 120px;
  height: 140px;
}

.ranked {
  // position and layout
  position: relative;

  // box model
  min-width: 395px;
  height: 330px;

  // background
  cursor: pointer;

  &-number {
    // position and layout
    position: absolute;
    left: -4px;
    top: 180px;

    // box model
    border-radius: 50%;
    width: 120px;
    height: 120px;

    // background
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);

    &-text {
      // position and layout
      position: relative;
      justify-content: center;

      // display
      display: flex;

      // typography
      font-size: 80px;
      font-weight: 900;
      color: white;
      -webkit-text-stroke: 6px white;
    }

    &-first {
      // position and layout
      position: absolute;
      left: 0px;
      top: 162px;

      // box model
      border-radius: 50%;
      width: 156px;
      height: 156px;

      // background
      background: rgba(217, 217, 217, 0.3);
      backdrop-filter: blur(12px);

      &-text {
        // position and layout
        display: flex;
        position: relative;
        justify-content: center;

        // typography
        font-size: 120px;
        line-height: 1.3;
        font-weight: 900;
        color: transparent;
        -webkit-text-stroke: 6px black;
      }
    }
  }

  &-image {
    // position and layout
    position: absolute;
    z-index: -1;
    left: calc(4rem - 8px);

    // box model
    width: 320px;
    height: 240px;

    // typography
    text-align: right;

    &-item {
      // box model
      border-radius: 12px;
      height: 100%; /* For Mozilla-based browsers */
      height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */

      object-fit: cover;
    }

    &-overlay {
      // position and layout
      position: absolute;
      bottom: 0px;

      // box model
      width: 320px;

      // Overshadow
      background: rgba(0, 0, 0, 0.5);
      padding: 22px 25px 22px 18px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      &-text {
        // clipping
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        white-space: pre-wrap;
        word-break: normal;

        // Typography
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 32px;
        margin-left: 25px;
        margin-right: 18px;
        letter-spacing: 1px;
        color: white;
      }
    }
  }
}
</style>
