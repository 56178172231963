<template>
  <div class="swiper-item-season" @click="onClickSeason()">
    <div class="swiper-item-season__text">
      <span class="swiper-item-season__text__title">
        {{ season.description }}
      </span>
    </div>
    <div class="swiper-item-season__actions" v-if="selectable">
      <div
        v-if="selectable && selected_season && selected_season.id == season.id"
      >
        <b-tooltip label="Deselect" position="is-bottom" type="is-dark">
          <check-box
            :value="selected_season.id == season.id"
            @input="selectSeason"
          />
        </b-tooltip>
      </div>
      <b-tooltip label="Edit" position="is-bottom" type="is-dark">
        <div
          class="swiper-item-season__actions-icon icon-pencil"
          @click="openSeasonModal"
        ></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import CheckBox from "@/components/shared/CheckBox.vue";

export default {
  name: "SwiperItemSeason",
  components: {
    CheckBox,
  },
  props: {
    season: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("series", ["selected_serie"]),
    ...mapGetters("seasons", ["selected_season"]),
  },
  methods: {
    ...mapActions("seasons", ["setSelectedSeason"]),
    ...mapActions("modal", ["setOpen", "setItem", "setType"]),
    selectSeason(value) {
      if (value) {
        this.setSelectedSeason(this.season);
      } else {
        this.setSelectedSeason(null);
      }
    },
    openSeasonModal() {
      this.setItem(this.season);
      this.setType("season");
      this.setOpen(true);
    },
    onClickSeason() {
      if (this.selected_season && this.selected_season.id == this.season.id) {
        this.setSelectedSeason(null);
      } else {
        this.setSelectedSeason(this.season);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-item-season {
  // Position and Layout
  column-gap: 27px;

  // Display and Visibility
  display: flex;

  // Box model
  border-radius: 12px;
  width: fit-content;
  min-width: 360.37px;
  max-width: 360.37px;
  // padding bottom for tooltip full visibility
  padding: 20px 20px 30px 20px;

  // background
  background-color: #e8ebed;
  cursor: pointer;

  // Typography
  color: black;

  &__text {
    // Position and Layout
    flex-direction: column;
    row-gap: 14px;

    // Display and Visibility
    display: flex;

    // Box model
    width: -webkit-fill-available;

    &__title {
      // Display and Visibility
      display: flex;

      // Clipping
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;

      // Typography
      font-weight: 700;
      letter-spacing: 1px;
    }

    &__author {
      // Typography
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.75px;
    }
  }

  &__actions {
    // position and layout
    align-items: center;
    column-gap: 1rem;

    // display and visibility
    display: flex;

    &-icon {
      // box model
      padding: 0px 4px;
      border-radius: 8px;

      // background
      background-color: #f17023;
      color: white;

      // typography
      font-size: 16px;

      // background
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
