<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ modal_title }}</p>
      <span
        class="modal-card-head-icon icon-x"
        @click="
          () => {
            setOpen(false);
            setItem(null);
          }
        "
      ></span>
    </header>
    <section class="modal-card-body">
      <b-field label="Season #">
        <b-input
          type="number"
          min="1"
          :max="maxSeasonNumber"
          v-model="number"
          placeholder="Season #"
          :disabled="!isSeasonNumberEditable"
        >
        </b-input>
      </b-field>

      <b-field label="Description">
        <b-input
          maxlength="500"
          type="textarea"
          v-model="description"
          placeholder="Description"
          required
        >
        </b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <div style="margin-left: auto; margin-right: 0">
        <button-icon
          v-if="item"
          @click="confirmDeleteSeason()"
          text="Delete season"
          iconClass="icon-x"
          iconSide="right"
          cssBackgroundColor="#FFFFFF"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="black"
        />
        <button-icon
          :disabled="!(this.number && this.description)"
          v-if="item"
          @click="update(season)"
          text="Update season"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
        />
        <button-icon
          :disabled="!(this.number && this.description)"
          v-if="!item"
          @click="create(season)"
          text="Create season"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import DOMPurify from "dompurify";

export default {
  name: "SeasonModal",
  components: {
    ButtonIcon,
  },
  data() {
    return {
      id: null,
      number: null,
      description: "",
    };
  },
  async mounted() {
    if (this.item) {
      this.id = this.item.id;
      this.number = Number(this.item.number);
      this.description = this.item.description;
    } else {
      this.number = this.maxSeasonNumber + 1;
    }
  },
  computed: {
    ...mapGetters("modal", ["item"]),
    ...mapGetters("series", ["selected_serie"]),

    season() {
      return {
        id: this.id || null,
        number: Number(this.number),
        description: this.description,
        serieid: this.selected_serie.id,
      };
    },
    modal_title() {
      return this.item ? `Update Season #${this.number}` : "Create New Season";
    },
    maxSeasonNumber() {
      return this.selected_serie.seasonsWithVideos.length;
    },
    isSeasonNumberEditable() {
      return this.item && this.maxSeasonNumber > 1;
    },
  },
  methods: {
    ...mapActions("seasons", ["createSeason", "updateSeason", "deleteSeason"]),
    ...mapActions("modal", ["setOpen", "setItem"]),
    confirmDeleteSeason() {
      const sanitizedNumber = DOMPurify.sanitize(this.season.number);
      this.$buefy.dialog.confirm({
        title: "Deleting Season",
        message: `Are you sure you want to <b>delete</b> the season #${sanitizedNumber}? </br>This action cannot be undone.`,
        confirmText: "Delete Season",
        type: "is-danger",
        onConfirm: () =>
          this.deleteSeason(this.season.id).then(() =>
            this.$emit("closeModal")
          ),
      });
    },
    update() {
      if (!(this.number && this.description)) return;
      const clone = JSON.parse(JSON.stringify(this.season));
      delete clone.serieid;
      this.updateSeason(clone).then(() => this.$emit("closeModal"));
    },
    create() {
      if (!(this.number && this.description)) return;
      this.createSeason(this.season).then(() => this.$emit("closeModal"));
    },
  },
};
</script>
