<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ modal_title }}</p>
      <span
        class="modal-card-head-icon icon-x"
        @click="
          () => {
            setOpen(false);
            setItem(null);
          }
        "
      ></span>
    </header>
    <section class="modal-card-body">
      <b-field label="Title">
        <b-input type="text" v-model="title" placeholder="Title" required>
        </b-input>
      </b-field>

      <b-field label="Description">
        <b-input
          maxlength="500"
          type="textarea"
          v-model="description"
          placeholder="Description"
          required
          rows="3"
        >
        </b-input>
      </b-field>

      <b-field label="Categories">
        <b-taginput
          v-model="selectedCategories"
          :data="filteredCategories"
          rounded
          open-on-focus
          autocomplete
          type="is-categories"
          field="name"
          placeholder="Add a Category"
          @typing="getFilteredCategories"
          @remove="getFilteredCategories"
          @focus="getFilteredCategories"
        >
        </b-taginput>
      </b-field>

      <b-field label="Casting">
        <b-taginput
          v-model="casting"
          autocomplete
          :data="filteredCastings"
          allow-new
          open-on-focus
          @typing="getFilteredCastings"
          @remove="getUpdatedCastings"
          @focus="getUpdatedCastings"
          ellipsis
          rounded
          type="is-casting"
          placeholder="Add a tag"
          aria-close-label="Delete this tag"
        ></b-taginput>
      </b-field>

      <b-field label="Hashtags">
        <b-taginput
          id="custom-taginput"
          v-model="hashtags"
          autocomplete
          :data="filteredHashtags"
          allow-new
          open-on-focus
          @typing="getFilteredHashtags"
          @remove="getUpdatedHashtags"
          @focus="getUpdatedHashtags"
          ellipsis
          rounded
          type="is-hashtags"
          placeholder="Add a tag"
          aria-close-label="Delete this tag"
        ></b-taginput>
      </b-field>

      <div class="columns" v-if="media_hosts">
        <div class="column">
          <b-field label="Media">
            <b-radio-button
              v-for="media_host in media_hosts"
              :key="media_host.id"
              v-model="media_host_id"
              :native-value="media_host.id"
              type="is-sorint is-light is-outlined"
            >
              <span>{{ media_host.name }}</span>
            </b-radio-button>
          </b-field>
          <b-field label="Code">
            <b-input type="text" v-model="code" placeholder="Code" required>
            </b-input>
          </b-field>
        </div>
        <div class="column">
          <div
            style="display: flex; justify-content: center"
            v-if="media_hosts"
          >
            <b-field label="Preview">
              <iframe
                :src="`${selected_media_host.embed_prefix}${code}`"
              ></iframe>
            </b-field>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div style="margin-left: auto; margin-right: 0">
        <button-icon
          v-if="item"
          @click="confirmDeleteVideo()"
          text="Delete video"
          iconClass="icon-x"
          iconSide="right"
          cssBackgroundColor="#FFFFFF"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="black"
        />
        <button-icon
          :disabled="!(this.title && this.description && this.code)"
          v-if="item"
          @click="update(video)"
          text="Update video"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
        />
        <button-icon
          :disabled="!(this.title && this.description && this.code)"
          v-if="!item"
          @click="create(video)"
          text="Create video"
          iconClass="icon-send"
          iconSide="right"
          cssBackgroundColor="#F17023"
          cssBorderRadius="12px"
          cssPadding="12px 16px"
          cssBorder="1px solid #E8EBED"
          cssTextColor="white"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import { mapActions, mapGetters } from "vuex";
import ButtonIcon from "@/components/shared/ButtonIcon.vue";
import DOMPurify from "dompurify";

export default {
  name: "VideoModal",
  components: {
    ButtonIcon,
  },
  data() {
    return {
      id: null,
      title: "",
      description: "",
      selectedCategories: [],
      casting: [],
      code: "",
      hashtags: [],
      media_host_id: "",
      created: null,
      filteredHashtags: [],
      filteredCastings: [],
      filteredCategories: [],
      promotional: false,
    };
  },
  async mounted() {
    const promise1 = this.fetchCategories();
    const promise2 = this.fetchMediaHosts();
    const promise3 = this.fetchHashtags();
    const promise4 = this.fetchCastings();
    await Promise.all([promise1, promise2, promise3, promise4]);
  },
  created() {
    if (this.item) {
      this.id = this.item.id;
      this.title = this.item.title;
      this.description = this.item.description;
      this.selectedCategories = this.item.categories;
      this.casting = this.item.casting;
      this.code = this.item.code;
      this.hashtags = this.item.hashtags;
      this.media_host_id = this.item.media_host_id;
      this.created = this.item.created;
      this.promotional = this.item.promotional;
    }
  },
  computed: {
    ...mapGetters("categories", { video_categories: "categories" }),
    ...mapGetters("modal", ["item", "episode_number"]),
    ...mapGetters("mediaHosts", ["media_hosts"]),
    ...mapGetters("admin", ["impersonated_user"]),
    ...mapGetters("videos", {
      video_hashtags: "hashtags",
      video_castings: "castings",
    }),
    ...mapGetters("seasons", ["selected_season"]),

    video() {
      return {
        id: this.id || null,
        title: this.title,
        description: this.description,
        categories: this.selectedCategories.map((category) => category.id),
        casting: this.arrayToString(this.casting),
        code: this.code,
        hashtags: this.arrayToString(this.hashtags),
        media_host_id: this.media_host_id,
        created: this.created,
        owner_username: utils.isAdmin()
          ? this.impersonated_user
          : this.$keycloak.tokenParsed.preferred_username,
        promotional: this.promotional,
      };
    },
    modal_title() {
      return this.item ? `Update Video "${this.title}"` : "Create New Video";
    },
    selected_media_host() {
      return (
        this.media_hosts.find((media) => media.id === this.media_host_id) ||
        this.media_hosts[0]
      );
    },
  },
  methods: {
    ...mapActions("categories", ["fetchCategories"]),
    ...mapActions("videos", [
      "createVideo",
      "updateVideo",
      "deleteVideo",
      "fetchHashtags",
      "fetchCastings",
    ]),
    ...mapActions("episodes", ["createEpisode"]),
    ...mapActions("modal", ["setOpen", "setItem"]),
    ...mapActions("mediaHosts", ["fetchMediaHosts"]),
    confirmDeleteVideo() {
      const sanitizedTitle = DOMPurify.sanitize(this.title);
      this.$buefy.dialog.confirm({
        title: "Deleting Video",
        message: `Are you sure you want to <b>delete</b> the video "${sanitizedTitle}"? </br>This action cannot be undone.`,
        confirmText: "Delete Video",
        type: "is-danger",
        onConfirm: () =>
          this.deleteVideo(this.video.id).then(() => this.$emit("closeModal")),
      });
    },
    update() {
      if (!(this.title && this.description)) return;
      this.updateVideo(this.video).then(() => this.$emit("closeModal"));
    },
    create() {
      if (!(this.title && this.description)) return;
      this.createVideo(this.video).then((newVideo) => {
        this.$emit("switchModal", "VideoCropper", newVideo);
        if (this.episode_number) {
          this.createEpisode({
            id: null,
            episode: Number(this.episode_number),
            seasonid: this.selected_season.id,
            videoid: newVideo.id,
          });
        }
      });
    },
    arrayToString(array) {
      return array.join("#");
    },
    getFilteredCategories() {
      this.filteredCategories = this.video_categories?.filter((el) => {
        return !this.selectedCategories.map((c) => c.id).includes(el.id);
      });
    },
    getFilteredHashtags(text) {
      this.filteredHashtags = this.video_hashtags
        ?.filter((el) => {
          return !this.hashtags.includes(el);
        })
        .filter((hashtag) => {
          return hashtag
            .toString()
            .toLowerCase()
            .includes(text.toString().toLowerCase());
        });
      if (text && text.length > 0) this.filteredHashtags.unshift(text);
    },
    getFilteredCastings(text) {
      this.filteredCastings = this.video_castings
        ?.filter((el) => {
          return !this.casting.includes(el);
        })
        .filter((casting) => {
          return casting
            .toString()
            .toLowerCase()
            .includes(text.toString().toLowerCase());
        });
      if (text && text.length > 0) this.filteredCastings.unshift(text);
    },
    getUpdatedHashtags() {
      this.filteredHashtags = this.video_hashtags?.filter((el) => {
        return !this.hashtags.includes(el);
      });
    },
    getUpdatedCastings() {
      this.filteredCastings = this.video_castings?.filter((el) => {
        return !this.casting.includes(el);
      });
    },
  },
  watch: {
    video_categories() {
      this.filteredCategories = this.video_categories;
    },
    video_hashtags() {
      this.filteredHashtags = this.video_hashtags;
    },
    video_castings() {
      this.filteredCastings = this.video_castings;
    },
  },
};
</script>
