import Vue from "vue";
import axios from "axios";

const isAdmin = () => {
  return Vue.prototype.$keycloak.tokenParsed.groups.includes("project42-admin");
};

async function getImage(imageUrl) {
  if (Vue.prototype.$config.sentry.environment === "local") {
    imageUrl = imageUrl.replace(
      "https://42.sorintdev.it/api/v1/",
      "http://localhost:8080/api/v1/api/v1/"
    );
  }
  return await axios
    .get(imageUrl, { responseType: "blob" })
    .then(async (response) => {
      return URL.createObjectURL(response.data);
    })
    .catch(() => {
      return "/assets/images/default.webp";
    });
}

export default { isAdmin, getImage };
