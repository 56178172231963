<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ modal_title }}</p>
      <button
        type="button"
        class="delete"
        @click="
          () => {
            setOpen(false);
            setItem(null);
          }
        "
      />
    </header>
    <section
      class="modal-card-body"
      style="display: flex; justify-content: center"
    >
      <div style="width: 600px; height: 450px" @click="modify()">
        <vueCropper
          ref="cropper"
          :img="initialImage"
          outputType="jpeg"
          :outputSize="0.5"
          :canMove="cropStarted"
          :canMoveBox="true"
          :full="true"
          :fixed="true"
          :fixedNumber="[4, 3]"
          :info="false"
          :mode="cropperMode"
        ></vueCropper>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div style="margin-left: auto; margin-right: 0">
        <b-field class="file is-primary" :class="{ 'has-name': !!file }">
          <b-upload
            @input="uploadFile($event)"
            v-model="file"
            class="file-label"
            v-show="!file && !isModified"
            accept="image/png, image/jpeg, image/jpg"
          >
            <span class="file-cta">
              <span class="file-label">Upload new picture</span>
            </span>
            <span class="file-name" v-if="file">
              {{ file.name }}
            </span>
          </b-upload>
        </b-field>
        <b-button
          v-if="file || (validInitialImage && isModified)"
          label="Cancel"
          type="is-danger is-outlined"
          @click="reset()"
        />
        <b-button
          v-if="file || (validInitialImage && isModified)"
          label="Update"
          type="is-primary"
          @click="updateImage()"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueCropper } from "vue-cropper";
import utils from "@/utils/utils";

export default {
  name: "CropperModal",
  components: {
    VueCropper,
  },
  data() {
    return {
      initialImage: null,
      file: null,
      cropperMode: "cover",
      cropStarted: false,
      validInitialImage: false,
      isModified: false,
    };
  },
  async mounted() {
    if (this.item?.image_url) {
      this.initialImage = await utils.getImage(this.item.image_url);

      if (this.initialImage) {
        this.cropperMode = "cover";
        this.$refs.cropper.startCrop();
        this.cropStarted = true;
        this.validInitialImage = true;
      }
    }
  },
  computed: {
    ...mapGetters("modal", ["item", "type"]),

    modal_title() {
      return `Update Image of "${this.item?.title}"`;
    },
  },
  methods: {
    ...mapActions("modal", ["setOpen", "setItem"]),
    ...mapActions("series", ["updateSerieImage"]),
    ...mapActions("videos", ["updateVideoImage"]),

    uploadFile(e) {
      this.initialImage = window.URL.createObjectURL(new Blob([e]));
      this.cropperMode = "contain";
      this.$refs.cropper.startCrop();
      this.cropStarted = true;
    },
    updateImage() {
      this.$refs.cropper.getCropData((imageData) => {
        if (this.type === "SeriesCropper") {
          this.updateSerieImage({
            id: this.item.id,
            image: imageData,
          }).then(() => this.$emit("closeModal"));
        } else if (this.type === "VideoCropper") {
          this.updateVideoImage({
            id: this.item.id,
            image: imageData,
          }).then(() => this.$emit("closeModal"));
        }
      });
    },
    async reset() {
      this.file = null;
      this.initialImage = (await utils.getImage(this.item.image_url)) || null;
      this.cropperMode = "cover";
      this.$refs.cropper.clearCrop();
      this.isModified = false;
    },
    modify() {
      this.isModified = true;
    },
  },
};
</script>
